import React from 'react'
import { Item, Move, Take } from '../actions'
import { Door, Room, Scene, Torch, ZPath } from '../scene'
import { LocationProps, Main } from '../ui'

/** @type {React.FunctionComponent} */
export default function InnRoomD({ items }) {
  return (
    <>
      <Scene>
        <Room />
        <Move to="InnHall">
          <Door />
        </Move>
        <Torch x={-200} y={0} z={800} />
        <Item item={items.axe}>
          <Take>
            <ZPath
              d="m 0,0 h 10 v -100 h 5 a 1,2 0 0 \1 0,30 30,35 0 0 \0 0,-70 1,2 0 0 \1 0,30 h -5 v -10 h -10 v 10 h -5 a 1,2 0 0 \1 0,-30 30,35 0 0 \0 0,70 1,2 0 0 \1 0,-30 h 5 z"
              fill="var(--background-color)"
              transform="translate(220, 50) rotate(200)"
              z={800}
            />
          </Take>
        </Item>
      </Scene>
      <Main>
        Room D.{' '}
        <Item item={items.axe}>
          You see an <Take>axe</Take>.
        </Item>{' '}
        There is a <Move to="InnHall">door</Move> leading out.
      </Main>
    </>
  )
}
InnRoomD.items = { axe: { id: 'axe' } }
InnRoomD.propTypes = LocationProps
