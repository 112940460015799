import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useCallback, useMemo, useState } from 'react'
import { Details } from '../actions'
import { point3d } from '../model'
import { Chair, Egg, Rect3d, Room, Scene, Table, ZCircle, ZPath, ZPolygon, ZRect } from '../scene'
import { LocationProps } from '../ui'

const ANCHOR = {
  base: {
    head: [0, -15],
    hat: [0, -30],
    leftEye: [-5, -15],
    rightEye: [5, -15],
    leftArm: [-20, -10],
    rightArm: [20, -10],
    armLength: [35, 35],
    leftLeg: [-10, 55],
    rightLeg: [10, 55],
    legLength: [35, 45]
  },
  topheavy: {
    arm: [23, -10]
  },
  egg: {
    head: [0, -10],
    arm: [20, 10],
    leg: [15, 55]
  },
  hourglass: {
    arm: [18, -10],
    leg: [15, 55]
  },
  frame: {
    arm: [20, -12.5]
  },
  wide: {
    arm: [30, -10],
    leg: [25, 55]
  },
  minisquare: {
    head: [0, 30],
    arm: [15, 30],
    leg: [10, 55]
  }
}

const FACIAL = {
  moustache: (p) => <ZPath d="m -10,-7 s 5,-5 10,0 v 0 s 5,-5 10,0 z" fill="black" {...p} />
}

const HATS = {
  none: () => null,
  top: (p) => (
    <g {...p}>
      <ZRect x={-10} y={-20} width={20} height={15} fill={p.fill} z={p.z} />
      <ZPath d="m -20,0 s 0,-5 10,-5 h 20 s 10,0 10,5" z={p.z} />
    </g>
  ),
  bob: (p) => (
    <>
      <ZPath
        d="m -15,5 Q -17,-10 0,-10 17,-10 15,5 v 5 h -5 v -5 h -3 v 5 h -5 v -5 h -4 v 5 h -5 v -5 h -3 v 5 h -5 v -5 z"
        {...p}
      />
    </>
  ),
  pony: (p) => <ZPath d="m 0,0 s 10,-10 15,15 z" {...p} />,
  swirl: (p) => <ZPath d="m 0,2 a \1 \1 0 0 \1 0,-5 a \1 \1 0 0 0 0,-5 a \1 \1 0 0 \1 0,10 z" {...p} />,
  helmet: (p) => (
    <ZPath
      d="m -15,5 v -5 l 5,-5 h 9 v -3 l -2,-3 3,-6 3,6 -2,3 v 3 h 9 l 5,5 v 20 h -5 v -10 l -5,-5 h -10 l -5,5 v 10 h -5 z"
      {...p}
    />
  )
}

const HATS_BACK = {
  top: (p) => <ZPath d="m -20,0 s 0,-5 10,-5 h 20 s 10,0 10,5 v 1 s 0,5 -10,5 h -20 s -10,0 -10,-5 z" {...p} />,
  bob: (p) => (
    <>
      <ZPath d="M -20,25 Q -17,-10 0,-10 17,-10 20,25 z" {...p} />
    </>
  ),
  pony: (p) => (
    <>
      <ZPath d="M -20,20 S -20,-20 0,0 z" {...p} />
    </>
  )
}
const EYES = {
  dots: (p) => <ZCircle r={1} {...p} />,
  squares: (p) => <ZRect x={-1} y={-1} width={2} height={2} {...p} />,
  circles: (p) => <ZCircle r={3} {...p} />,
  lines: (p) => <ZRect x={-1} y={-3} width={2} height={6} {...p} />,
  exes: (p) => <ZPolygon points="2,0 4,2 2,4 0,2 -2,4 -4,2 -2,0 -4,-2 -2,-4 0,-2 2,-4 4,-2" {...p} />,
  shine: (p) => <ZPolygon points="4,0 2,1 1,2 0,4 -1,2 -2,1 -4,0 -2,-1 -1,-2 0,-4 1,-2 2,-1" {...p} />,
  triangles: (p) => <ZPolygon points="-3,0 3,0 0,3" {...p} />
}

const BODIES = {
  rectangle: (p) => <ZRect x={-20} y={-15} width={40} height={70} {...p} />,
  wide: (p) => <ZRect x={-30} y={-15} width={60} height={70} {...p} />,
  minisquare: (p) => <ZRect x={-15} y={25} width={30} height={30} {...p} />,
  topheavy: (p) => <ZPolygon points="-25,-15 -15,30 -15,55 15,55 15,30 25,-15" {...p} />,
  hourglass: (p) => <ZPolygon points="-20,-15 -10,20 -20,55 20,55 10,20 20,-15" {...p} />,
  egg: (p) => <Egg x={0} y={37} width={40} {...p} height={95} />,
  frame: ({ transform, ...p }) => (
    <g transform={transform}>
      <ZRect x={-20} y={-15} width={40} height={5} {...p} />
      <ZRect x={-2.5} y={-10} width={5} height={60} {...p} />
      <ZRect x={-15} y={50} width={30} height={5} {...p} />
    </g>
  )
}

const HEADS = {
  circle: (p) => <ZCircle r={15} cy={-15} {...p} />,
  square: (p) => <ZRect width={30} height={30} x={-15} y={-30} {...p} />,
  bigjaw: (p) => <ZPolygon points="-15,0 -10,-30 10,-30 15,0" {...p} />,
  bigtop: (p) => <ZPolygon points="-10,0 -15,-30 15,-30 10,0" {...p} />,
  egg: (p) => <Egg x={0} y={-10} width={20} {...p} height={40} />,
  skull: (p) => (
    <ZPath
      d="m 0,0 q -7.5,0 -7.5,-7.5 -7.5,0 -7.5,-11.25 0,-11.25 7.5,-11.25 h 15 q 7.5,0 7.5,11.25 0,11.25 -7.5,11.25 0,7.5 -7.5,7.5"
      {...p}
    />
  )
}

const LEG_UPPER = {
  needle: (p) => <ZRect x={-2.5} y={0} width={5} height={35} {...p} />,
  thin: (p) => <ZRect x={-2.5} y={0} width={5} height={35} {...p} />,
  rectangle: (p) => <ZRect x={-5} y={0} width={10} height={35} {...p} />,
  thick: (p) => <ZRect x={-7.5} y={0} width={15} height={35} {...p} />,
  bellbottom: (p) => <ZRect x={-5} y={0} width={10} height={35} {...p} />,
  ant: (p) => <ZPolygon points="-2.5,0 0,40 2.5,0" {...p} />,
  thighs: (p) => <ZPolygon points="-7.5,0 -10,25 -5,35 5,35 10,25 7.5,0" {...p} />
}

const LEG_LOWER = {
  needle: (p) => <ZPolygon points="-2.5,0 0,45 2.5,0" {...p} />,
  thin: (p) => <ZRect x={-2.5} y={0} width={5} height={45} {...p} />,
  rectangle: (p) => <ZRect x={-5} y={0} width={10} height={45} {...p} />,
  thick: (p) => <ZRect x={-7.5} y={0} width={15} height={45} {...p} />,
  bellbottom: (p) => <ZPolygon points="-5,0 -10,45 10,45 5,0" {...p} />,
  ant: (p) => <ZPolygon points="-2.5,0 0,45 2.5,0" {...p} />,
  thighs: (p) => <ZPolygon points="-5,0 -2,45 2,45 5,0" {...p} />
}

const ARM_UPPER = {
  thin: (p) => <ZRect x={-2.5} y={0} width={5} height={35} {...p} />,
  rectangle: (p) => <ZRect x={-5} y={0} width={10} height={35} {...p} />,
  biceps: (p) => <ZPolygon points="-10,0 -5,35 5,35 10,0" {...p} />,
  ant: (p) => <ZPolygon points="-2.5,0 0,40 2.5,0" {...p} />,
  rounded: (p) => <ZPath d="M 8,3 a 5,5 \0 \0 \0 -16,0 L -5,35 a 2.5,2.5 \0 \0 \0 10,0 z" {...p} />,
  line: (p) => <ZPath d="m 0,0 l 0,35" {...p} />
}

const ARM_LOWER = {
  thin: (p) => <ZRect x={-2.5} y={0} width={5} height={35} {...p} />,
  rectangle: (p) => <ZRect x={-5} y={0} width={10} height={35} {...p} />,
  biceps: (p) => <ZRect x={-5} y={0} width={10} height={35} {...p} />,
  ant: (p) => <ZPolygon points="-2.5,0 0,35 2.5,0" {...p} />,
  rounded: (p) => <ZPath d="M 5,0 a 5,5 \0 \0 \0 -10,0 L -2.5,35 a 2.5,2.5 \0 \0 \0 5,0 z" {...p} />,
  line: (p) => <ZPath d="m 0,0 l 0,35" {...p} />
}

function useAnchor(body, z) {
  const base = _.cloneDeep(ANCHOR.base)
  const anchor = { ...ANCHOR[body] } ?? {}
  if (anchor.arm) {
    anchor.leftArm = [-anchor.arm[0], anchor.arm[1]]
    anchor.rightArm = anchor.arm
  }
  if (anchor.leg) {
    anchor.leftLeg = [-anchor.leg[0], anchor.leg[1]]
    anchor.rightLeg = anchor.leg
  }
  return _.mapValues(_.merge(base, anchor), ([x, y]) => point3d([x, y, z]))
}

function usePose(pose) {
  const base = { leftArm: [10, -10], rightArm: [-10, 10], leftLeg: [0, 0], rightLeg: [0, 0] }
  if (pose.leg) {
    pose.leftLeg = [pose.leg[0], pose.leg[1]]
    pose.rightLeg = [pose.leg[0], pose.leg[1]]
  }
  return _.merge(base, pose)
}
function Figure({
  head = 'rectangle',
  eyes = 'dots',
  eyeColor = 'black',
  body = 'rectangle',
  skin = 'white',
  arms = 'rectangle',
  legs = 'rectangle',
  facials = [FACIAL.moustache],
  hat = 'none',
  pose = {},
  joints = false,
  x = 0,
  y = 200,
  z = 0,
  wave = false,
  walk = false
}) {
  pose = usePose(pose)
  const anchor = useAnchor(body, z)
  const Head = HEADS[head]
  const Body = BODIES[body]
  const LegUpper = LEG_UPPER[legs]
  const LegLower = LEG_LOWER[legs]
  const ArmUpper = ARM_UPPER[arms]
  const ArmLower = ARM_LOWER[arms]
  const Eye = EYES[eyes]
  const Hat = HATS[hat]
  const HatBack = HATS_BACK[hat]
  ;[x, y] = point3d([x, y - 135, z])

  const leftRightLegs = [
    <g key="leftLeg" transform={`translate(${anchor.leftLeg}) rotate(${pose.leftLeg[0]})`}>
      <LegUpper name="leg-left-upper" fill={skin} z={z}></LegUpper>
      <LegLower
        name="leg-left-lower"
        transform={`translate(0,${anchor.legLength[0]}) rotate(${pose.leftLeg[1]})`}
        fill={skin}
        z={z}
      >
        {walk && (
          <animateTransform
            attributeName="transform"
            type="rotate"
            dur="2s"
            values="10;0;10"
            additive="sum"
            repeatCount="indefinite"
          />
        )}
      </LegLower>
      {joints && <ZCircle r={1} z={z} fill="red" />}
      {joints && <ZCircle r={1} z={z} fill="red" transform={`translate(0,${anchor.legLength[0]})`} />}
      {walk && (
        <animateTransform
          attributeName="transform"
          type="rotate"
          dur="2s"
          values="10;-20;10"
          additive="sum"
          repeatCount="indefinite"
        />
      )}
    </g>,
    <g key="rightLeg" transform={`translate(${anchor.rightLeg}) rotate(${pose.rightLeg[0]})`}>
      <LegUpper name="leg-right-upper" fill={skin} z={z} />
      <LegLower
        name="leg-right-lower"
        transform={`translate(0,${anchor.legLength[0]}) rotate(${pose.rightLeg[1]})`}
        fill={skin}
        z={z}
      >
        {walk && (
          <animateTransform
            attributeName="transform"
            type="rotate"
            dur="2s"
            begin="1s"
            values="10;0;10"
            additive="sum"
            repeatCount="indefinite"
          />
        )}
      </LegLower>
      {joints && <ZCircle r={1} z={z} fill="red" />}
      {joints && <ZCircle r={1} z={z} fill="red" transform={`translate(0,${anchor.legLength[0]})`} />}
      {walk && (
        <animateTransform
          attributeName="transform"
          type="rotate"
          dur="2s"
          begin="1s"
          values="10;-20;10"
          additive="sum"
          repeatCount="indefinite"
        />
      )}
    </g>
  ]
  return (
    <g transform={`translate(${x}, ${y})`}>
      <g transform={`translate(${anchor.head})`}>
        {HatBack && <HatBack name="hat-back" transform={`translate(${anchor.hat})`} fill="grey" z={z} />}
        <Head fill={skin} z={z} />
        <Eye transform={`translate(${anchor.leftEye})`} fill={eyeColor} z={z} />
        <Eye transform={`translate(${anchor.rightEye})`} fill={eyeColor} z={z} />
        <Hat name="hat-front" transform={`translate(${anchor.hat})`} fill="grey" z={z} />
        {facials}
        {joints && <ZCircle r={1} z={z} fill="red" />}
      </g>

      <g transform={`translate(${anchor.rightArm}) rotate(${pose.rightArm[0]}) `}>
        <ArmUpper fill={skin} z={z} />
        <ArmLower transform={`translate(0,${anchor.armLength[0]}) rotate(${pose.rightArm[1]})`} fill={skin} z={z}>
          {walk && (
            <animateTransform
              attributeName="transform"
              type="rotate"
              dur="2s"
              begin="1s"
              values="-10;-20;-10"
              additive="sum"
              repeatCount="indefinite"
            />
          )}
        </ArmLower>
        {joints && <ZCircle r={1} z={z} fill="red" />}
        {joints && <ZCircle r={1} z={z} fill="red" transform={`translate(0,${anchor.armLength[0]})`} />}
        {walk && (
          <animateTransform
            attributeName="transform"
            type="rotate"
            dur="2s"
            begin="1s"
            values="-15;15;-15"
            additive="sum"
            repeatCount="indefinite"
          />
        )}
      </g>

      <Body fill={skin} z={z} />
      {joints && <ZCircle r={1} z={z} fill="red" />}

      {pose.leftLeg[0] < 0 || walk ? leftRightLegs.reverse() : leftRightLegs}

      <g transform={`translate(${anchor.leftArm}) rotate(${pose.leftArm[0]})`}>
        <ArmUpper fill={skin} z={z} transform="scale(-1,1)" />
        <ArmLower
          transform={`translate(0,${anchor.armLength[0]}) rotate(${pose.leftArm[1]}) scale(-1,1)`}
          fill={skin}
          z={z}
        >
          {wave && (
            <animateTransform
              attributeName="transform"
              type="rotate"
              dur="2s"
              values="30;-30;30"
              additive="sum"
              repeatCount="indefinite"
            />
          )}
        </ArmLower>
        {joints && <ZCircle r={1} z={z} fill="red" />}
        {joints && <ZCircle r={1} z={z} fill="red" transform={`translate(0,${anchor.armLength[0]})`} />}
        {walk && (
          <animateTransform
            attributeName="transform"
            type="rotate"
            dur="2s"
            values="-15;15;-15"
            additive="sum"
            repeatCount="indefinite"
          />
        )}
      </g>

      {walk && <animateMotion begin="0s" dur="10s" repeatCount="indefinite" path="m 0,0 l 300,0" />}
    </g>
  )
}
Figure.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  z: PropTypes.number,
  head: PropTypes.string,
  body: PropTypes.string,
  legs: PropTypes.string,
  arms: PropTypes.string,
  eyes: PropTypes.string,
  eyeColor: PropTypes.string,
  hat: PropTypes.string,
  skin: PropTypes.string,
  facials: PropTypes.arrayOf(PropTypes.func),
  height: PropTypes.number,
  pose: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.number)),
  joints: PropTypes.bool,
  wave: PropTypes.bool,
  walk: PropTypes.bool
}

function Select({ label, value, map, onChange }) {
  const options = useMemo(() => Object.keys(map).map((key) => <option key={key}>{key}</option>), [map])
  return (
    <div>
      <label>{label}:</label>
      <select value={value} onChange={(e) => onChange(e.target.value)}>
        {options}
      </select>
    </div>
  )
}

Select.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  map: PropTypes.objectOf(PropTypes.func),
  onChange: PropTypes.func
}

function Color({ label, value, onChange }) {
  return (
    <div>
      <label>{label}:</label>
      <input type="color" value={value} onChange={(e) => onChange(e.target.value)} />
    </div>
  )
}
Color.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func
}

function Toggle({ label, value, onChange }) {
  return (
    <div>
      <label>{label}:</label>
      <input
        type="checkbox"
        checked={value}
        onChange={(e) => [console.debug(e.target.checked), Boolean(onChange(e.target.checked))]}
      />
    </div>
  )
}
Toggle.propTypes = {
  label: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func
}

/** @type {React.FunctionComponent} */
export default function CharacterBuilder() {
  const [head, setHead] = useState('circle')
  const [body, setBody] = useState('rectangle')
  const [arms, setArms] = useState('rectangle')
  const [legs, setLegs] = useState('rectangle')
  const [eyes, setEyes] = useState('dots')
  const [hat, setHat] = useState('none')
  const [skin, setSkin] = useState('#ffffff')
  const [eyeColor, setEyeColor] = useState('#000000')
  const [moustache, setMoustache] = useState(false)
  const randomize = useCallback(() => {
    setHead(_.sample(Object.keys(HEADS)))
    setBody(_.sample(Object.keys(BODIES)))
    setArms(_.sample(Object.keys(ARM_UPPER)))
    setLegs(_.sample(Object.keys(LEG_UPPER)))
    setEyes(_.sample(Object.keys(EYES)))
    setHat(_.sample(Object.keys(HATS)))
    setEyeColor('#' + _.random(0xffffff).toString(16).padStart(6, '0'))
    setSkin('#' + _.random(0xffffff).toString(16).padStart(6, '0'))
    setMoustache(_.random() === 0)
  }, [])

  return (
    <>
      <Scene>
        <Room />
        <Figure
          head={head}
          body={body}
          legs={legs}
          arms={arms}
          eyes={eyes}
          hat={hat}
          skin={skin}
          eyeColor={eyeColor}
          facials={[moustache && <FACIAL.moustache z={700} />]}
          x={-300}
          z={700}
          walk={true}
        />
        <Rect3d points="-400,0,700 -200,200,700" fill="var(--background-color)" />
        <Rect3d points="400,0,700 200,200,700" fill="var(--background-color)" />
        <Figure
          head={head}
          body={body}
          legs={legs}
          arms={arms}
          eyes={eyes}
          hat={hat}
          skin={skin}
          eyeColor={eyeColor}
          facials={[moustache && <FACIAL.moustache />]}
          pose={{ leftArm: [90, 90] }}
          wave={true}
        />
        <Table x={300} z={100} lookAt="desk" />
        <Chair x={255} z={150} lookAt="body" />
        <Figure
          head={head}
          body={body}
          legs={legs}
          arms={arms}
          eyes={eyes}
          hat={hat}
          skin={skin}
          eyeColor={eyeColor}
          facials={[moustache && <FACIAL.moustache z={150} />]}
          pose={{ leg: [-80, 80] }}
          x={280}
          y={215}
          z={150}
        />
      </Scene>
      <aside>
        <button onClick={randomize}>🎲</button>
      </aside>
      <main>
        <div style={{ display: 'inline-block', margin: 2 }}>
          <Select label="head" value={head} onChange={setHead} map={HEADS} />
          <Select label="eyes" value={eyes} onChange={setEyes} map={EYES} />
          <Color label="eyeColor" value={eyeColor} onChange={setEyeColor} />
          <Select label="hat" value={hat} onChange={setHat} map={HATS} />
          <Toggle label="moustache" value={moustache} onChange={setMoustache} />
        </div>
        <div style={{ display: 'inline-block', margin: 2 }}>
          <Select label="body" value={body} onChange={setBody} map={BODIES} />
          <Select label="arms" value={arms} onChange={setArms} map={ARM_UPPER} />
          <Select label="legs" value={legs} onChange={setLegs} map={LEG_UPPER} />
          <Color label="skin" value={skin} onChange={setSkin} />
        </div>
      </main>
      <Details of="object">Extra information.</Details>
    </>
  )
}
CharacterBuilder.propTypes = LocationProps
CharacterBuilder.isExtra = true
