import React from 'react'
import { Details, Item, Look, Move, Take } from '../actions'
import { Arrow, Box3d, Flame, Rect3d, Room, Scene } from '../scene'
import { LocationProps, Main } from '../ui'

export default function InnEntrance({ items }) {
  return (
    <>
      <Scene>
        <Room />
        <Box3d points="-250,50,200 -150,200,800" stroke="brown" fill="var(--background-color)" lookAt="counter" />
        <Rect3d points="-150,-150,800 150,200,800" moveTo="InnHall" />
        <polygon points="120,110 155,95 190,110" fill="black" />
        <Box3d points="260,100,700 340,165,800" fill="var(--background-color)" lookAt="kitchen" />
        <Flame x={145} y={90} left={true} delay={`1000ms`} dur={2000} />
        <Flame x={155} y={80} left={false} delay="0ms" dur={2000} />
        <Arrow side="back" moveTo="Forest" />
        <Arrow side="front" z={800} moveTo="InnHall" />
      </Scene>
      <Main>
        Entrance. There is a <Look at="counter">counter</Look> on one side of the room, and a{' '}
        <Look at="kitchen">dining area</Look> on the other. The <Move to="InnHall">hall</Move> is in the back. There is
        a <Move to="Forest">door</Move> heading out.
      </Main>
      <Details of="counter">
        There is a corpse lazily hidden behind the counter. A crushed box lays in pieces beyond it.{' '}
        <Item item={items.gold}>
          There are a few <Take>coins</Take>.
        </Item>
      </Details>
      <Details of="kitchen">A cold pot of soup sits over the embers of a wood stove.</Details>
    </>
  )
}
InnEntrance.items = { gold: { id: 'gold', quantity: 5 } }
InnEntrance.propTypes = LocationProps
