import React from 'react'
import { Move } from '../actions'
import { Arrow, Door, Rect3d, Scene } from '../scene'
import { LocationProps, Main } from '../ui'

export default function InnHall({ firstVisit }) {
  return (
    <>
      <Scene>
        <Rect3d points="-150,-200,-300 -150,200,1400" name="wall_left" />
        <Rect3d points="+150,-200,-300 +150,200,1400" name="wall_right" />

        <Door side="left" x={-150} z={0} name="doorC" moveTo="InnRoomC" />
        <Door side="right" x={+150} z={0} name="doorD" moveTo="InnRoomD" />
        <Door side="left" x={-150} z={800} name="doorA" moveTo="InnRoomA" />
        <Door side="right" x={+150} z={800} name="doorB" moveTo="InnRoomB" />
        <Arrow side="back" moveTo="InnRoomE" />

        {/* <Rect3d points="-150,-150,1400 150,200,1400" stroke="none" moveTo="InnEntrance" /> */}
        <Arrow side="front" moveTo="InnEntrance" />
      </Scene>
      <Main>
        You see four rooms: <Move to="InnRoomA">Room A</Move> and <Move to="InnRoomC">Room C</Move> on the left,{' '}
        <Move to="InnRoomB">Room B</Move> and <Move to="InnRoomD">Room D</Move> on the right.{' '}
        <Move to="InnRoomE">Room E</Move> at the end of the hall is where you woke up, and at the opposite end is the{' '}
        <Move to="InnEntrance">entrance</Move>. {firstVisit && ' A smell festers in the air.'}
      </Main>
    </>
  )
}
InnHall.propTypes = LocationProps
