import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useCallback, useMemo, useState } from 'react'
import { Item, Move, Take } from '../actions'
import { Arrow, Polygon3d, Scene } from '../scene'
import { LocationProps, Main } from '../ui'

/**
 * @param {React.SVGProps}
 */
function Tree({ x, z, ...props }) {
  return (
    <Polygon3d
      points={[
        [x - 10, 200, z],
        [x - 10, 190, z],
        [x - 100, 190, z],
        [x, -200, z],
        [x + 100, 190, z],
        [x + 10, 190, z],
        [x + 10, 200, z]
      ]}
      {...props}
    />
  )
}
Tree.propTypes = {
  x: PropTypes.number,
  z: PropTypes.number
}

export default function Forest({ firstVisit, items }) {
  const [count, setCount] = useState(0)
  const onExplore = useCallback(() => setCount(count + 1), [count])
  const trees = useMemo(() => {
    const positions = _.sortBy(
      [
        [-400, 0],
        [400, 0],
        ..._.range(8).map((i) => [-1600 + i * 200, 1e4]),
        ..._.range(9).map((i) => [200 + i * 200, 1e4]),
        ..._.range(30).map(() => [_.random(-1600, -200), _.random(100, 1e4)]),
        ..._.range(30).map(() => [_.random(+1600, +200), _.random(100, 1e4)])
      ],
      '1'
    ).reverse()
    return positions
  }, [count])
  return (
    <>
      <div id="background" className="dark" />
      <Scene>
        {trees.map(([x, z], i) => (
          <Tree
            className="tree"
            key={`${i}`}
            x={x}
            z={z}
            fill="green"
            style={{ filter: `brightness(${100 - z / 110}%)` }}
          />
        ))}
        <Arrow side="back" moveTo="InnEntrance" />
        <a>
          <Arrow side="front" z={5000} onClick={onExplore} />
        </a>
      </Scene>
      <Main>
        {firstVisit && count === 0 && 'You stumble out into the forest. '}
        You can try to find your <a onClick={onExplore}>way out</a>, or go back to the <Move to="InnEntrance">inn</Move>
        .{' '}
        <Item item={count > 30 && items.map}>
          You have been in this forest so long, you could make a <Take>map</Take>.
        </Item>
      </Main>
    </>
  )
}
Forest.items = { map: { id: 'map' } }
Forest.propTypes = LocationProps
