import _ from 'lodash'
import React, { createContext, useContext } from 'react'
import { GameContext } from './model'
import PropTypes from 'prop-types'

export function Move({ to, and, children }) {
  const { move } = useContext(GameContext)
  return <a onClick={() => [move(to), and?.()]}>{children}</a>
}
Move.propTypes = {
  to: PropTypes.string.isRequired,
  and: PropTypes.func,
  children: PropTypes.node
}

export function Look({ children, and, at = String(children) }) {
  const { look } = useContext(GameContext)
  return <a onClick={() => [look(at), and?.()]}>{children}</a>
}
Look.propTypes = {
  at: PropTypes.string.isRequired,
  and: PropTypes.func,
  children: PropTypes.node
}

const ItemContext = createContext(undefined)
export function Item({ item, or = null, children }) {
  return (_.isArray(item) ? item[0] : item) ? <ItemContext.Provider value={item}>{children}</ItemContext.Provider> : or
}
Item.propTypes = {
  item: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
  or: PropTypes.node,
  children: PropTypes.node
}

export function Take({ item, and, children }) {
  const itemContext = useContext(ItemContext)
  const { take } = useContext(GameContext)
  return <a onClick={() => [take(item || itemContext), and?.()]}>{children}</a>
}
Take.propTypes = {
  item: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
  and: PropTypes.func,
  children: PropTypes.node
}

export function Details({ of, children }) {
  const { look, lookingAt } = useContext(GameContext)
  return (
    <dialog open={lookingAt === of}>
      <a aria-hidden={true} onClick={() => [look('')]} className="dismiss"></a>
      {children}
    </dialog>
  )
}
Details.propTypes = {
  of: PropTypes.string,
  children: PropTypes.node
}
