import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { Component, useCallback, useEffect, useState } from 'react'
import { Move } from '../actions'
import Listen from '../Listen'
import * as Locations from '../locations'
import { GameContext } from '../model'

/** @type {React.FunctionComponent} */
export function App() {
  const [currentlyAt, setScreen] = useState('LandingPage')
  const [firstVisit, setFirstVisit] = useState(true)
  const [locations, setLocations] = useState(() =>
    localStorage.getItem('locations')
      ? JSON.parse(localStorage.getItem('locations'))
      : { [currentlyAt]: { items: Locations[currentlyAt].items } }
  )
  const [inventory, setInventory] = useState(() =>
    localStorage.getItem('inventory') ? JSON.parse(localStorage.getItem('inventory')) : {}
  )
  const [lookingAt, setLookingAt] = useState('')

  useEffect(() => {
    const persist = () => {
      localStorage.setItem('inventory', JSON.stringify(inventory))
      localStorage.setItem('locations', JSON.stringify(locations))
    }
    window.addEventListener('unload', persist)
    return () => {
      window.removeEventListener('unload', persist)
    }
  }, [locations, inventory])

  const updateLocation = useCallback(
    (location, update) => {
      const newLoations = _.merge({ ...locations }, { [location]: update })
      localStorage.setItem('locations', JSON.stringify(newLoations))
      setLocations(newLoations)
    },
    [locations]
  )
  const reset = useCallback(() => {
    setLocations({})
    setInventory({})
    // case: reset() + move(InnRoomE), move uses stale state.
    _.keys(locations).forEach((i) => _.unset(locations, i))
  }, [locations])
  const move = useCallback(
    (to) => {
      /** These rooms are excluded from adventure, and will not let you continue from them. */
      if (!Locations[to].isExtra) {
        localStorage.setItem('load', to)
      }
      const nextFirstVisit = !locations[to]
      if (nextFirstVisit) {
        setLocations({
          ...locations,
          [to]: {
            items: Locations[to].items ?? {}
          }
        })
      }
      setFirstVisit(nextFirstVisit)

      setScreen(to)
      setLookingAt('')
    },
    [currentlyAt, locations]
  )

  const look = useCallback(
    (at) => {
      setLookingAt(at)
    },
    [currentlyAt]
  )

  const take = useCallback(
    /** @param {Item[]} items */
    (...items) => {
      items = _.flatten(items)
      setInventory({
        ...inventory,
        ..._.keyBy(
          items
            .filter((i) => !!i)
            .map((item) =>
              inventory[item.id]?.quantity || item.quantity
                ? {
                    ...item,
                    quantity: (inventory[item.id] ? inventory[item.id].quantity ?? 1 : 0) + (item.quantity ?? 1)
                  }
                : item
            ),
          'id'
        )
      })
      setLocations(_.omit(locations, ...items.filter((i) => !!i).map(({ id }) => `${currentlyAt}.items.${id}`)))
    },
    [currentlyAt, locations, inventory]
  )

  const Location = Locations[currentlyAt]

  const toggleDarkMode = useCallback(() => {
    localStorage.setItem('darkMode', document.body.classList.toggle('darkMode'))
  }, [])

  return (
    <GameContext.Provider
      value={{ move, look, take, reset, updateLocation, locations, currentlyAt, lookingAt, inventory }}
    >
      <ErrorBoundary>
        <Location
          key={`${currentlyAt}`}
          firstVisit={firstVisit}
          items={locations[currentlyAt]?.items}
          lookingAt={lookingAt}
        />
        <aside>
          {currentlyAt !== 'LandingPage' && (
            <div>
              <Move to="LandingPage">🏠</Move>
            </div>
          )}
          <div>
            <a id="toggleDarkMode" onClick={toggleDarkMode}>
              ☀
            </a>
          </div>
          {window.speechSynthesis && (
            <div id="listen">
              <Listen />
            </div>
          )}
        </aside>
      </ErrorBoundary>
    </GameContext.Provider>
  )
}

class ErrorBoundary extends Component {
  state = { message: '' }
  static getDerivedStateFromError(error) {
    return { message: error.message }
  }

  render() {
    if (this.state.message) {
      return (
        <>
          <div id="background" className="black" />
          <GameContext.Consumer>
            {({ move }) => (
              <main>
                Your vision fades to black and death whispers in your ear:
                <blockquote>{this.state.message}</blockquote>
                <a onClick={() => [move('LandingPage'), this.setState({ message: '' })]}>
                  You slowly open your eyes...
                </a>
              </main>
            )}
          </GameContext.Consumer>
        </>
      )
    }
    return this.props.children
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node
}
