import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useReducer } from 'react'
import { Item, Take } from '../actions'
import { Arrow, Scene } from '../scene'
import { LocationProps, Main } from '../ui'

function reducer({ combo }, { increment, decrement }) {
  combo[increment ?? decrement] =
    increment !== undefined ? (combo[increment] + 1) % 10 : combo[decrement] ? combo[decrement] - 1 : 9
  return { combo, open: _.isEqual(combo, [1, 4, 9, 5]) }
}

function Dial({ x, y, index, combo, dispatch }) {
  return (
    <>
      <text id={`dial${index}`} x={x} y={y} fontSize={60} fill="black" textAnchor="middle">
        {combo[index]}
        <animateMotion path="m 0,-45 l 0,+45" dur="300ms" begin={`dial${index}_increment.click`} />
        <animateMotion path="m 0,+10 l 0,-10" dur="300ms" begin={`dial${index}_decrement.click`} />
        <animateTransform
          attributeName="transform"
          type="scale"
          begin={`dial${index}_increment.click`}
          dur="300ms"
          from="1, 0.2"
          to="1, 1"
        />
        <animateTransform
          attributeName="transform"
          type="scale"
          begin={`dial${index}_decrement.click`}
          dur="300ms"
          from="1, 0.2"
          to="1, 1"
        />
      </text>
      <rect
        id={`dial${index}_increment`}
        x={x - 15}
        y={y - 50}
        width={30}
        height={30}
        cursor="pointer"
        pointerEvents="bounding-box"
        onClick={() => dispatch({ increment: index })}
      ></rect>
      <rect
        id={`dial${index}_decrement`}
        x={x - 15}
        y={y - 20}
        width={30}
        height={30}
        cursor="pointer"
        pointerEvents="bounding-box"
        onClick={() => dispatch({ decrement: index })}
      ></rect>
    </>
  )
}
Dial.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  index: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
  combo: PropTypes.arrayOf(PropTypes.number).isRequired
}

export default function Safe({ items }) {
  const [{ combo, open }, dispatch] = useReducer(reducer, { combo: [0, 0, 0, 0] })
  return (
    <>
      <Scene>
        <rect x={-150} y={-150} width={300} height={300} />
        <rect x={-140} y={-140} width={280} height={280} />
        {items.moonstone && !open && (
          <>
            <Dial x={-60} y={0} index={0} dispatch={dispatch} combo={combo} />
            <Dial x={-20} y={0} index={1} dispatch={dispatch} combo={combo} />
            <Dial x={+20} y={0} index={2} dispatch={dispatch} combo={combo} />
            <Dial x={+60} y={0} index={3} dispatch={dispatch} combo={combo} />
          </>
        )}
        <Arrow moveTo="InnRoomB" />
      </Scene>
      <Main>
        You see a safe in the corner of the room.{' '}
        <Item item={[items.gold, items.moonstone]} or="The safe is empty.">
          {open && (
            <>
              The safe clicks open. There is a decent amount of <Take>gold</Take> in a small bag, and a small white{' '}
              <Take>stone</Take> with a faint glow.
            </>
          )}
        </Item>
      </Main>
    </>
  )
}
Safe.items = { gold: { id: 'gold', quantity: 200 }, moonstone: { id: 'moonstone' } }
Safe.propTypes = LocationProps
