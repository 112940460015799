import _ from 'lodash'
import React, { useEffect, useMemo, useReducer } from 'react'
import { Scene } from '../scene'
import { LocationProps } from '../ui'

function smooth(f) {
  return (d, t) => (1 + Math.cos(Math.PI * f(d, t + d))) / 2
}
function bounce(d, t) {
  return t % (d * 2) < d ? (t % d) / d : 1 - (t % d) / d
}

function linear(d, t) {
  return (t % d) / d
}

function position(a, b, p) {
  return a + (b - a) * p
}

function move({ x1, x2, y1, y2, dur, style }, time) {
  let tFunc = linear
  switch (style) {
    case 'bounce':
      tFunc = smooth(bounce)
      break
  }

  return {
    x: position(x1, x2, tFunc(dur, time)),
    y: position(y1, y2, tFunc(dur, time))
  }
}

function updateActiveGrounds(grounds, trigger) {
  grounds.forEach((ground) => {
    if (ground.activeWhen === trigger.id) {
      ground.active = trigger.active ?? false
    } else if (ground.activeWhen === `-${trigger.id}`) {
      ground.active = !trigger.active ?? true
    }
  })
}

function init() {
  const initial = {
    viewBox: '-400 -400 800 400',
    pause: false,
    time: 0,
    ground: [
      { type: 'platform', x: -400, y: 0, width: 800, height: 10 },
      { type: 'platform', x: 150, y: 30, width: 100, height: 10, activeWhen: '1' },
      { type: 'platform', x: 290, y: 60, width: 100, height: 10, activeWhen: '2' },
      { type: 'platform', x: 150, y: 90, width: 100, height: 10, activeWhen: '3' },
      { type: 'platform', x: 290, y: 120, width: 100, height: 10, activeWhen: '4' },
      { type: 'platform', x: 150, y: 150, width: 100, height: 10, activeWhen: '5' },
      { type: 'platform', x: 290, y: 180, width: 100, height: 10, activeWhen: '6' },
      { type: 'platform', x: -150, y: 210, width: 400, height: 10, conveyor: 'right' },
      { type: 'platform', x: -280, y: 180, width: 100, height: 10, conveyor: 'left' },
      {
        type: 'platform',
        width: 100,
        height: 10,
        activeWhen: '7',
        move: { x1: -390, y1: 180, x2: -390, y2: 430, dur: 150, style: 'bounce' }
      },
      { type: 'wall', x: -150, y: 20, width: 20, height: 30 }
    ],
    wall: [{ x: -150, y: 20, width: 20, height: 30 }],
    relic: [{ x: -150, y: 20 }],
    trigger: [
      { id: '1', x: 375, y: 0, type: 'timer', dur: 90 },
      { id: '2', x: 165, y: 30, type: 'timer', dur: 90 },
      { id: '3', x: 375, y: 60, type: 'timer', dur: 90 },
      { id: '4', x: 165, y: 90, type: 'timer', dur: 90 },
      { id: '5', x: 375, y: 120, type: 'timer', dur: 90 },
      { id: '6', x: 165, y: 150, type: 'timer', dur: 90 },
      { id: '7', x: -340, y: 120, type: 'toggle' }
    ],
    dx: 0,
    dy: 0,
    x: 0,
    y: 0
  }

  initial.trigger.forEach((trigger) => updateActiveGrounds(initial.ground, trigger))

  return initial
}

function reducer(state, action) {
  if (typeof action === 'string') {
    action = { type: action }
  }
  switch (action.type) {
    case '+a':
      state.dx = state.dx == 0 ? -1 : state.dx
      return state
    case '-a':
      state.dx = state.dx <= -1 ? 0 : state.dx
      return state
    case '+d':
      state.dx = state.dx == 0 ? +1 : state.dx
      return state
    case '-d':
      state.dx = state.dx <= +1 ? 0 : state.dx
      return state
    case '+w':
      state.dy = state.dy == 0 ? +8 : state.dy
      return state
    case '-w':
      // state.dy = state.dy <= -1 ? 0 : state.dy
      return state
    case '+s':
      state.dy = state.dy == 0 ? +1 : state.dy
      return state
    case '-s':
      state.dy = state.dy <= +1 ? 0 : state.dy
      return state
    case '+r':
      return init()
    case '+escape':
      state.pause = !state.pause
      break
    case 'pause':
      state.pause = true
      break
    case 'resume':
      state.pause = false
      break
    case 'tick': {
      if (state.pause) return state

      state.ground = state.ground.map((ground) =>
        ground.move
          ? {
              ...ground,
              ...move(ground.move, state.time)
            }
          : ground
      )

      state.x = _.clamp(state.x + state.dx * 4, -375, 375)
      let ground =
        state.dy <= 0 &&
        state.ground.find(
          ({ x, y, width, active = true }) =>
            active && _.inRange(state.x, x, x + width) && _.inRange(state.y + state.dy, y - 16, y + 3)
        )
      if (ground) {
        state.y = ground.y
        state.dy = 0
        if (ground.conveyor === 'right') {
          state.x += 2
        } else if (ground.conveyor === 'left') {
          state.x -= 2
        }
      } else {
        state.y = _.clamp(state.y + state.dy, 0, state.y + 100)
        state.dy = _.clamp(state.dy - 1.01, -16, +8)
      }

      let wall = state.wall.find(
        ({ x, y, width, height }) => _.inRange(state.x, x - 25, x + width + 25) && _.inRange(state.y, y - height, y)
      )
      if (wall) {
        state.x =
          state.x < wall.x ? Math.max(state.x - 6, wall.x - 25) : Math.min(state.x + 6, wall.x + wall.width + 25)
      }

      let trigger = state.trigger.find(
        ({ x, y }) => _.inRange(state.x, x - 25, x + 25) && _.inRange(state.y, y - 3, y + 25)
      )

      if (trigger) {
        if (trigger !== state.lastTrigger) {
          trigger.active = trigger.type === 'toggle' ? !trigger.active : true
          state.lastTrigger = trigger
          updateActiveGrounds(state.ground, trigger)
        }
      } else if (state.lastTrigger) {
        if (state.lastTrigger.type === 'timer') {
          state.lastTrigger.start = state.time
        } else if (state.lastTrigger.type !== 'toggle') {
          state.lastTrigger.active = false
          updateActiveGrounds(state.ground, state.lastTrigger)
        }
        // state.trigger = state.trigger.map((t) => (t === state.lastTrigger ? { ...t, active: false } : t))
        delete state.lastTrigger
      }

      state.trigger
        .filter((trigger) => trigger.type === 'timer' && trigger.start && trigger.start + trigger.dur <= state.time)
        .forEach((trigger) => {
          delete trigger.start
          trigger.active = false
          updateActiveGrounds(state.ground, trigger)
        })

      const viewY = Number(state.viewBox.split(' ')[1])
      if (!_.inRange(-state.y, viewY + 100, viewY + 300)) {
        state.viewBox = `-400 ${Math.min(-state.y + (-state.y < viewY + 200 ? -100 : -300), -400)} 800 400`
      }

      state.time++
      break
    }
    default:
      console.debug(action)
      return state
  }
  return { ...state }
}

const keypress = (dispatch) => {
  const keys = {}
  const onkeypress = (keycode) => {
    keycode = _.toLower(keycode)
    const pressed = keycode[0] == '+'
    const key = _.tail(keycode)
    if (keys[key] !== pressed) {
      dispatch(keycode)
      if (key == 'd' && keys['a']) {
        dispatch(pressed ? '-a' : '+a')
      } else if (key == 'a' && keys['d']) {
        dispatch(pressed ? '-d' : '+d')
      }
    }
    keys[key] = pressed
  }
  onkeypress.clear = () => Object.keys(keys).forEach((k) => delete keys[k])
  return onkeypress
}
/** @type {React.FunctionComponent} */
export default function Platformer() {
  const [state, dispatch] = useReducer(reducer, 0, init)
  const onKeyPress = useMemo(() => keypress(dispatch), [])
  useEffect(() => {
    let throttled = _.throttle(() => dispatch('tick'), 20)
    let id
    const tick = () => {
      throttled()
      id = window.requestAnimationFrame(tick)
    }
    id = window.requestAnimationFrame(tick)
    return () => window.cancelAnimationFrame(id)
  }, [])

  useEffect(() => {
    /** @param {KeyboardEvent} e */
    const keyup = (e) => onKeyPress('-' + e.key)
    window.addEventListener('keyup', keyup)
    /** @param {KeyboardEvent} e */
    const keydown = (e) => onKeyPress('+' + e.key)
    window.addEventListener('keydown', keydown)

    const focus = () => {
      onKeyPress('-a')
      onKeyPress('-d')
      onKeyPress('-a')
      onKeyPress('-d')
    }
    window.addEventListener('focus', focus)
    return () => {
      window.removeEventListener('keydown', keydown)
      window.removeEventListener('keyup', keyup)
      window.removeEventListener('blur', blur)
    }
  }, [])

  useEffect(() => {
    const blur = () => {
      onKeyPress.clear()
      onKeyPress('-a')
      onKeyPress('-d')
      onKeyPress('-a')
      onKeyPress('-d')
      dispatch('pause')
    }
    return () => window.addEventListener('blur', blur)
  }, [state.pause])

  return (
    <>
      <Scene viewBox={state.viewBox}>
        {state.ground.map(({ x, y, width, height, active = true, conveyor }, i) => (
          <rect
            key={`ground-${i}`}
            x={x}
            y={-y}
            width={width}
            height={height}
            strokeDasharray={active ? undefined : '1 4'}
          >
            {conveyor && (
              <animate
                attributeName="stroke-dasharray"
                values={conveyor === 'left' ? '18 2 0 0; 0 2 18 0; 0 0 18 2' : '0 0 18 2; 0 2 18 0; 18 2 0 0'}
                keyTimes={conveyor === 'left' ? '0;.9;1' : '0;.1;1'}
                dur="250ms"
                repeatCount="indefinite"
              />
            )}
          </rect>
        ))}
        {state.wall.map(({ x, y, width, height }, i) => (
          <rect key={`wall-${i}`} x={x} y={-y} width={width} height={height} />
        ))}
        {state.trigger.map(({ x, y, active }, i) => (
          <rect
            key={`trigger-${i}`}
            x={x - 5}
            y={active ? -y - 6 : -y - 10}
            width={10}
            height={active ? 6 : 10}
            fill={active ? 'red' : 'none'}
          />
        ))}
        <circle transform={`translate(${state.x},${-state.y})`} fill="grey" stroke="none" cy={-25} r={25} />
      </Scene>
      <main>
        {_.without(Object.keys(state), 'ground', 'wall', 'relic', 'trigger', 'lastTrigger').map((k) => (
          <div key={k}>
            {k}: {`${_.isObjectLike(state[k]) ? JSON.stringify(state[k]) : state[k]}`}
          </div>
        ))}
      </main>
      <dialog open={state.pause}>
        <div>PAUSED</div>
        <div>
          <button onClick={() => dispatch('resume')}>Resume</button>
        </div>
      </dialog>
    </>
  )
}
Platformer.propTypes = LocationProps
Platformer.isExtra = true
