import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { Move } from '../actions'
import { Scene } from '../scene'
import { LocationProps } from '../ui'

function Wind({ x, y, begin }) {
  return (
    <use href="#wind" x={x} y={y}>
      <set attributeName="stroke-dasharray" to="0 0 0 50" />
      <animate
        attributeName="stroke-dasharray"
        values="0 0 0 50; 0 0 40 10; 0 50 0 0; 0 50 5 0"
        dur="10s"
        keyTimes="0; .1; .2; 1"
        repeatCount="indefinite"
        begin={begin}
      />
    </use>
  )
}
Wind.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  begin: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

function Cloud({ x = 0, y = 0, width = 1000, dur, begin, children }) {
  return (
    <g filter="url(#cloud)" fill="var(--color)" stroke="var(--color)" opacity=".1" transform={`translate(${x}, ${y})`}>
      {children}
      {begin ? (
        <animateMotion path={`m 0,0 h ${800 + width}`} dur={dur ?? '60s'} begin={begin} repeatCount="indefinite" />
      ) : (
        <animateMotion
          path={`m 0,0 h ${width} m -${width * 2},0 h ${width}`}
          dur={dur ?? '180s'}
          repeatCount="indefinite"
          additive="sum"
        />
      )}
    </g>
  )
}
Cloud.propTypes = {
  id: PropTypes.string,
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.number,
  begin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  dur: PropTypes.string,
  children: PropTypes.node
}

function TextCloud({ fontSize = 300, children, ...props }) {
  return (
    <Cloud {...props}>
      <text fill="inherit" fontSize={fontSize} filter="grayscale(100%) contrast(30%)">
        {children}
      </text>
    </Cloud>
  )
}
TextCloud.propTypes = {
  ...Cloud.PropTypes,
  fontSize: PropTypes.number
}

let ID = 0
function Icon({ id: givenId, x, y, children }) {
  const id = useMemo(() => givenId || `icon${ID++}`, [givenId])
  return (
    <>
      <circle name="pulse" r={0} cx={x} cy={y} clipPath="url(#clipEdge)" pointerEvents="none">
        <animate
          begin={`${id}.click`}
          attributeName="r"
          values="1 ; 20; 0"
          dur="5s"
          keyTimes="0; .2; 1"
          repeatCount="1"
        />
        <animate
          begin={`${id}.click`}
          attributeName="stroke-width"
          values="10; 1; 0"
          dur="5s"
          keyTimes="0; .1; 1"
          repeatCount="1"
        />
        <animate
          begin={`${id}.click`}
          attributeName="stroke-opacity"
          values="1 ; 0; 0"
          dur="5s"
          keyTimes="0; .2; 1"
          repeatCount="1"
        />
      </circle>
      <g id={id} transform={`translate(${x},${y})`} fill="var(--background-color)" cursor="pointer">
        {children}
      </g>
    </>
  )
}
Icon.propTypes = {
  id: PropTypes.string,
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.number,
  begin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  dur: PropTypes.string,
  children: PropTypes.node
}

/** @type {React.FunctionComponent} */
export default function City() {
  return (
    <>
      <Scene>
        <defs>
          <rect id="mapEdge" x={-375} y={-175} width={750} height={350} filter="url(#paper)" />
          <path
            id="castle"
            fill="var(--background-color)"
            d="m -3.5,8 v -8 l -3,-3 v -4 h 3 v 2 h 2 v -2 h 3 v 2 h 2 v -2 h 3 v 4 l -3,3 v 8 z"
          />
          <path id="home" fill="var(--background-color)" d="m -5 5 l 0 -7 5 -5 5 5 0 7 z" />
          <path
            id="mountains"
            fill="var(--background-color)"
            d="m -25,0 l 10,-20 5,10 10,-20 10,20 5,-10 10,20"
            stroke="grey"
          />
          <path
            id="waves"
            d="m -15 0 a 5 5 0 0 0 5 -5, 1 1 0 0 0 10 0, 1 1 0 0 0 10 0, 5 5 0 0 0 5 5"
            stroke="skyblue"
          />
          <path id="wind" d="m 0 0 q 30,0 30,-10 a 1,1 0 0 0 -10,0" />
          <filter id="paper">
            <feTurbulence type="turbulence" baseFrequency="0.03" numOctaves="4" result="turbulence" seed={2} />
            <feDisplacementMap in="SourceGraphic" in2="turbulence" scale="15" />
          </filter>
          <filter id="cloud" x="-100%" y="-100%" width="300%" height="300%">
            <feTurbulence type="fractalNoise" baseFrequency=".02" numOctaves="1" result="b" seed="2" />
            <feColorMatrix type="hueRotate">
              <animate attributeName="values" values="0;360" dur="12s" repeatCount="indefinite" />
            </feColorMatrix>
            <feDisplacementMap in="SourceGraphic" scale="120" xChannelSelector="B" yChannelSelector="R" />
            <feMorphology operator="dilate" radius="1" />
          </filter>
          <clipPath id="clipEdge" filter="url(#paper)">
            <rect id="mapEdge" x={-375} y={-175} width={750} height={350} filter="url(#paper)" />
          </clipPath>
        </defs>

        <use id="map" href="#mapEdge" />
        {/* {_.range(10, 400, 40).map((i) => (
          <circle key={`c:${i}`} r={i} clipPath="url(#clipEdge)" strokeOpacity={0.05} filter="url(#paper)" />
        ))} */}
        {_.range(225, 375, 50).map((x) =>
          _.range(-110, 150, 50).map((y) => (
            <use key={`mtn:${x}-${y}`} href="#mountains" x={x} y={x % 275 ? y : y + 25} />
          ))
        )}
        {_.range(-340, -220, 20).map((x) =>
          _.range(-150, 160, 20).map((y) => (
            <use key={`wtr:${x}-${y}`} href="#waves" x={x} y={x % 40 === 0 ? y : y + 10}>
              <animateMotion
                path="m 0 0 a 1 1 0 0 0 0 3 a 1 1 0 0 0 0 -3"
                begin={`${(340 + x) * 10}ms`}
                dur="5s"
                repeatCount="indefinite"
              />
            </use>
          ))
        )}
        <Icon x={150} y={50}>
          <use href="#castle" />
        </Icon>
        <Icon x={0} y={-125}>
          <use href="#home" />
        </Icon>
        <Icon>
          <path
            name="scales"
            fill="var(--background-color)"
            d="M 0,0 a 4 4 0 1 0 4 4, 4 4 0 0 1 -4 -4, 4 4 0 1 0 4 -4, 4 4 0 0 1 -4 4, 4 4 0 1 0 -4 4, 4 4 0 0 1 4 -4, 4 4 0 1 0 -4 -4, 4 4 0 0 1 4 4"
          />
        </Icon>

        <Wind x={-175} y={125} begin={1} />
        <Wind x={150} y={-25} begin={4} />

        <g clipPath="url(#clipEdge)" filter="url(#paper)" pointerEvents="none">
          <Cloud id="cloud1" x={-600} y={95} begin="10s">
            <circle fill="inherit" r="30" cx={+100} cy={-25} />
            <circle fill="inherit" r="50" cx={+75} cy={+40} />
            <circle fill="inherit" r="20" cx={+20} cy={-25} />
            <circle fill="inherit" r="25" cx={+25} cy={+25} />
          </Cloud>
          <TextCloud x={-700} y={70} begin="40s" dur="80s" fontSize={90}>
            👁👄👁
          </TextCloud>
          <TextCloud x={-500} y={0} width={1100} fontWeight={900} dur="180s">
            Clouds.
          </TextCloud>
          <TextCloud x={-1100} y={150} width={1000} begin="0s" dur="130s" fontSize={450}>
            😂
          </TextCloud>
          <TextCloud x={0} y={150} width={1000} fontSize={200} dur="110s">
            🐇
          </TextCloud>
          <TextCloud x={-600} y={-80} width={1000} fontSize={120} dur="50s">
            🥩🍖
          </TextCloud>
        </g>
      </Scene>
      <main>
        For now, you can go back to the <Move to="InnEntrance">inn</Move>.
      </main>
    </>
  )
}
City.items = { item: { id: 'item' } }
City.propTypes = LocationProps
