import _ from 'lodash'
import { createContext } from 'react'
/* eslint no-unused-vars:0  */
/**
 * @typedef {{id: string }} Item
 * @typedef {{firstVisit: boolean, items: {[id: string]: Item}}} LocationProps
 * @typedef {{
 *  (props: LocationProps) => JSX.Element,
 *  items?: {[id: string]: Item},
 *  isExtra?: boolean
 * }} Location
 */
export const GameContext = createContext({
  reset() {},
  /** @param {string} to */
  move(to) {},
  /** @param {string} at */
  look(at) {},
  /** @param {Item} item */
  take(item) {},
  /** @type {{[id: string]: {items: {[id: string]: Item}}}} */
  locations: {},
  currentlyAt: '',
  lookingAt: '',
  /** @type {{[id: string]: Item}} */
  inventory: {}
})

/**
 * Adjust numbers defined in 2D to their equivalents at Z.
 * Can escape a number using '\\', so it will not adjust.
 * @param {string} str
 * @param {number} z
 * @returns
 */
export function adjustToZ(str, z) {
  const numbers = /\\?[+-]?\d+(\.\d+)?/g
  let search
  let lastIndex = 0
  let result = ''
  while ((search = numbers.exec(str))) {
    const n = search[0]
    result += str.slice(lastIndex, numbers.lastIndex - n.length)
    lastIndex = numbers.lastIndex
    result += n.startsWith('\\') ? n.slice(1) : atZ(n, z)
  }
  result += str.slice(lastIndex)
  return result
}

/**
 *
 * @param {string|number} points
 * @param {number} z
 * @returns
 */
export function atZ(points, z) {
  if (points === '' || points === ' ') {
    return points
  }
  if (points === '0') {
    return '0'
  }
  if (!_.isNaN(+points)) {
    return (+points / Math.log2(+z / 400 + 2)).toFixed(3)
  }

  return adjustToZ(points, z)
}

/**
 *
 * @param {[number | string, number | string, number | string]} coordinates
 * @returns {[number, number]}
 */
export function point3d([x, y, z]) {
  return [atZ(x, z), atZ(y, z)]
}
