import React, { useContext } from 'react'
import { Move } from '../actions'
import { GameContext } from '../model'

export default function LandingPage() {
  const { reset, move, locations } = useContext(GameContext)
  return (
    <>
      <h1>Adventure Game</h1>
      <main>
        <a onClick={() => [reset(), move('InnRoomE')]}>Start</a>{' '}
        {locations.InnRoomE && (
          <>
            or <Move to={localStorage.getItem('load')}>Continue</Move>
          </>
        )}{' '}
        <ul>
          <li>
            <Move to="Fight">Fight</Move>
          </li>
          <li>
            <Move to="Cards">Cards</Move>
          </li>
          <li>
            <Move to="CharacterBuilder">Character Builder</Move>
          </li>
          <li>
            <Move to="Shooter">Shooter</Move>
          </li>
          <li>
            <Move to="Platformer">Platformer</Move>
          </li>
        </ul>
      </main>
    </>
  )
}
LandingPage.isExtra = true
