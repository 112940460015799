import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { Details, Item, Look, Move, Take } from '../actions'
import { Box3d, Door, Flame, Room, Scene } from '../scene'
import { LocationProps, Main } from '../ui'

function Ash({ x = 100, y = 100, left = false }) {
  return (
    <polygon points={[x - 75, y, x, y - 75, x + 75, y].join()} fill="black" cursor="pointer">
      <animateTransform
        attributeName="transform"
        type="translate"
        begin="click"
        dur="1000ms"
        by={left ? -100 : 100}
        fill="freeze"
        additive="sum"
        restart="never"
      />
    </polygon>
  )
}
Ash.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  left: PropTypes.bool
}

/** @type {React.FunctionComponent} */
export default function InnRoomB({ items }) {
  return (
    <>
      <Scene>
        <Room />
        <Door moveTo="InnHall" />
        <Look at="ash">
          <polygon points="-40,0 0,-20 40,0" transform="translate(100, 105)" fill="black" />
        </Look>
        <Box3d name="bed" points="-400,150,50 -200,200,200" fill="var(--background-color)" lookAt="room" />
        <Box3d name="safe" points="350,150,350 400,200,400" fill="var(--background-color)" moveTo="Safe" />
        <Flame x={-290} y={40} left={true} delay="0ms" dur={2000} />
        <Flame x={260} y={90} left={false} delay="400ms" dur={2000} />
        <Flame x={-230} y={110} left={true} delay="800ms" dur={2000} />
        <Flame x={-260} y={10} left={false} delay="1200ms" dur={2000} />
        <Flame x={250} y={100} left={true} delay="1600ms" dur={2000} />
      </Scene>
      <Main>
        <Look at="room">Room B</Look>.{' '}
        <Item item={items.bow}>
          A <Take>bow</Take> lies on the bed.
        </Item>{' '}
        There is a <Move to="InnHall">door</Move> leading out.
      </Main>
      <Details of="room">
        The sheets on the bed are singed and the floor is charred. The room smells of <Look at="ash">ash</Look>.
      </Details>
      <Details of="ash">
        <div>There is a large pile of ash on the ground.</div>
        <Item item={items.ring} or="You found a simple ring with an etched inscription: A+E / 1495.">
          <svg width={600} height={300}>
            <Take>
              <circle r={10} cx={200} cy={210} fill="red" />
            </Take>
            {_.range(25).map((i) => (
              <Ash key={`${i}`} x={200 + (i % 5) * 50} y={100 + Math.floor(i / 5) * 50} left={i % 2 === 0} />
            ))}
          </svg>
        </Item>
      </Details>
    </>
  )
}
InnRoomB.items = { bow: { id: 'bow' }, ring: { id: 'ring' } }
InnRoomB.propTypes = LocationProps
