import React from 'react'
import { Look, Move, Details, Take, Item } from '../actions'
import { Door, Egg, Room, Scene } from '../scene'
import { LocationProps, Main } from '../ui'

/** @type {React.FunctionComponent} */
export default function InnRoomA({ items }) {
  return (
    <>
      <div id="background" className="dark" />
      <Scene>
        <Room />
        <Door moveTo="InnHall" />
        <Look at="body1">
          <Egg x={0} y={140} width={200} height={100} fill="var(--background-color)" />
        </Look>
        <Look at="body2">
          <Egg width={75} height={100} fill="var(--background-color)" transform="translate(300 150) rotate(135)" />
        </Look>
      </Scene>
      <Main>
        Room A. You can make out in the dark two things: <Look at="body1">a mass</Look> in the middle of the room, and{' '}
        <Look at="body2">something</Look> against the wall. There is a <Move to="InnHall">door</Move> leading out.
      </Main>
      <Details of="body1">
        You see the body of a large man sprawled out on his back, with{' '}
        <Item item={items.arrow} or="a hole in">
          a single <Take>arrow</Take> protruding through
        </Item>{' '}
        his forehead.
      </Details>
      <Details of="body2">
        Curled up against the far wall, a bruised corpse of a small man
        <Item item={items.dagger}>
          , his fist still clutched around a <Take>dagger</Take>
        </Item>
        .
      </Details>
    </>
  )
}
InnRoomA.items = { arrow: { id: 'arrow' }, dagger: { id: 'dagger' } }
InnRoomA.propTypes = LocationProps
