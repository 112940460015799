import _ from 'lodash'
import React, { useCallback, useEffect, useReducer, useRef } from 'react'
import { Move } from '../actions'
import { Scene } from '../scene'
import './fight.css'

function reducer(state, phase) {
  switch (phase) {
    case 'attack.start': {
      const damage = _.random(1, 6)
      return _.merge(
        { ...state },
        { phase: 'attack.start', enemy: { health: [state.enemy.health[0] - damage] }, details: { damage } }
      )
    }
    case 'attack.end': {
      if (state.enemy.health[0] > 0) {
        const damage = _.random(1, 6)
        return _.merge(
          { ...state },
          { phase: 'defend.start', player: { health: [state.player.health[0] - damage] }, details: { damage } }
        )
      } else {
        return _.merge({ ...state }, { phase: 'enemy.death' })
      }
    }
    case 'defend.end': {
      if (state.player.health[0] > 0) {
        return _.merge({ ...state }, { phase: 'combat.turn' })
      } else {
        return _.merge({ ...state }, { phase: 'player.death' })
      }
    }
    case 'reset': {
      return _.merge(
        { ...state },
        {
          phase: 'reset',
          player: { health: [10] },
          enemy: { health: [10] }
        }
      )
    }
    default: {
      return _.merge({ ...state }, { phase })
    }
  }
}

function init() {
  return {
    phase: 'combat.turn',
    player: { health: [10, 10], magic: [10, 10], status: [] },
    enemy: { health: [10, 10], status: [] }
  }
}

export default function Fight() {
  const [{ phase, player, enemy, details }, dispatch] = useReducer(reducer, {}, init)
  /** @type {React.MutableRefObject<SVGElement>} */
  const attackTrigger = useRef(null)
  /** @type {React.MutableRefObject<SVGElement>} */
  const defendTrigger = useRef(null)
  /** @type {React.MutableRefObject<SVGElement>} */
  const enemyRef = useRef(null)
  /** @type {React.MutableRefObject<SVGElement>} */
  const playerRef = useRef(null)

  const play = useCallback((trigger) => trigger.current.dispatchEvent(new MouseEvent('click')), [])

  useEffect(() => {
    switch (phase) {
      case 'attack.start': {
        play(attackTrigger)
        _.delay(() => dispatch('attack.end'), 750)
        break
      }
      case 'defend.start': {
        play(defendTrigger)
        _.delay(() => dispatch('defend.end'), 750)
        break
      }
      case 'enemy.death': {
        enemyRef.current.classList.add('fadeAway')
        console.debug('enemy killed')
        _.delay(() => dispatch('combat.end'), 750)
        break
      }
      case 'player.death': {
        playerRef.current.classList.add('fadeAway')
        console.debug('player died')
        _.delay(() => dispatch('combat.end'), 750)
        break
      }
      case 'reset': {
        playerRef.current.classList.remove('fadeAway')
        enemyRef.current.classList.remove('fadeAway')
        _.delay(() => dispatch('combat.turn'), 100)
      }
    }
  }, [phase])

  return (
    <>
      <Scene>
        <circle id="attackTrigger" ref={attackTrigger} />
        <circle id="defendTrigger" ref={defendTrigger} />

        <g ref={enemyRef} id="enemy">
          <circle r={50} cx={250} cy={-100} strokeWidth={2} stroke="red"></circle>
          <polygon points="0,0 20,5 20,10 0,5" transform="translate(200,-120)" fill="black" />
          <polygon points="0,0 -20,5 -20,10 0,5" transform="translate(260,-120)" fill="black" />
          <polygon points="0,0 5,0 5,20 0,20" transform="translate(210,-110)" fill="black" />
          <polygon points="0,0 5,0 5,20 0,20" transform="translate(243,-110)" fill="black" />
          <polyline points="150,-30 225,10 250,-50 280,10 200,-10" strokeWidth={2} stroke="red" />
          <polyline points="250,-50 250,50" strokeWidth={2} stroke="red" />
          <polyline points="200,150 200,75 250,50 230,100 270,150" strokeWidth={2} stroke="red" />
          <animateMotion
            id="attack"
            begin="attackTrigger.click"
            dur="250ms"
            repeatCount="1"
            path="m 0,0 l -10,0 l 18,0 l -14,0 l 10,0 z"
          />
        </g>
        <circle ref={playerRef} id="player" r={50} cx={-250} cy={50} stroke="green">
          <animateMotion
            begin="defendTrigger.click"
            dur="250ms"
            repeatCount="1"
            path="m 0,0 l -10,0 l 18,0 l -14,0 l 10,0 z"
          />
        </circle>
        <rect
          x={-400}
          y={-195}
          width={Math.max((enemy.health[0] / enemy.health[1]) * 200, 0)}
          height={10}
          style={{
            transition: 'width 500ms'
          }}
        ></rect>
        <rect
          x={200}
          y={182}
          width={Math.max((player.health[0] / player.health[1]) * 200, 0)}
          height={10}
          style={{
            transition: 'width 500ms'
          }}
        />
      </Scene>
      <main>
        {phase === 'attack.start' && <>You hit the enemy for {details.damage} damage!</>}
        {phase === 'defend.start' && <>The enemy hit you for {details.damage} damage!</>}
        {phase === 'enemy.death' && <>You killed the enemy!</>}
        {phase === 'player.death' && <>The enemy killed you!</>}
        {phase === 'combat.turn' && (
          <>
            <button onClick={() => dispatch('attack.start')}>Attack</button>
            <button onClick={() => dispatch('attack.start')}>Fireball</button>
          </>
        )}
        {phase === 'combat.end' && (
          <>
            <button onClick={() => dispatch('reset')}>Again?</button>
            <Move to="LandingPage">
              <button>Leave</button>
            </Move>
          </>
        )}
      </main>
    </>
  )
}
Fight.isExtra = true
