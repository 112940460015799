import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useContext, useMemo } from 'react'
import { Details, Look, Move } from './actions'
import { GameContext } from './model'

if (localStorage.getItem('darkMode') === 'true') {
  console.debug('dark mode')
  document.body.classList.add('darkMode')
}

function Inventory() {
  const { inventory } = useContext(GameContext)
  const text = useMemo(() => {
    const items = _.map(Object.keys(inventory), (s) =>
      s === 'gold'
        ? inventory.gold.quantity + ' gold coins'
        : inventory[s]?.quantity > 1
        ? `${inventory[s].quantity} ${s}s`
        : s.match(/^[aeiou]/)
        ? 'an ' + s
        : 'a ' + s
    )

    return items.length > 1
      ? _.initial(items).join(', ') + ' and ' + _.last(items)
      : items.length === 1
      ? _.first(items)
      : 'nothing'
  }, [inventory])

  return <Details of="inventory">Holding {text}.</Details>
}

function Debug() {
  const { locations, currentlyAt } = useContext(GameContext)
  return (
    <Details of="debug">
      <div>Location: {currentlyAt}</div>
      <div>Items: {(locations[currentlyAt]?.items && Object.keys(locations[currentlyAt]?.items).join()) || 'none'}</div>
    </Details>
  )
}

export function Main({ showInventory = true, showMap = true, children }) {
  const { inventory } = useContext(GameContext)
  return (
    <>
      <aside>
        {showInventory && (
          <div id="inventory">
            <Look at="inventory">Inventory</Look>
          </div>
        )}
        {/* @TODO don't always show the map. */}
        {(showMap || inventory.map) && (
          <div>
            <Move to="Map">Map</Move>
          </div>
        )}
        <div id="debug">
          <Look at="debug">👁</Look>
        </div>
      </aside>
      <main>{children}</main>
      <Inventory />
      <Debug />
    </>
  )
}
Main.propTypes = {
  showInventory: PropTypes.bool,
  showMap: PropTypes.bool,
  children: PropTypes.node
}

export const LocationProps = {
  firstVisit: PropTypes.bool,
  items: PropTypes.objectOf(PropTypes.shape({ id: PropTypes.string }))
}
