import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { Look, Move } from './actions'
import { atZ, point3d } from './model'

function join(name, part) {
  return name?.concat('_' + part) ?? part
}

function chunk3d(points) {
  if (typeof points === 'string') {
    points = points.split(/\s*[ ,]\s*/).map(Number)
  }

  if (!_.isArray(points[0])) {
    points = _.chunk(points, 3)
  }

  return points
}

function convert3dTo2d(points) {
  return chunk3d(points).map(point3d).join(' ')
}

const adjustableProps = new Set(['x', 'y', 'width', 'height', 'd', 'points', 'r', 'cx', 'cy'])
export function ZAdjusted(Element) {
  /** @param {React.SVGProps} props */
  function ZAdjustedElement(props) {
    if (props.z) {
      const z = +props.z
      const adjustedProps = { ..._.mapValues(props, (v, k) => (adjustableProps.has(k) ? atZ(v, z) : v)) }
      return <Element {...adjustedProps} />
    }
    return <Element {...props} d={props.d?.replace(/\\/g, '')} />
  }
  ZAdjustedElement.propTypes = { d: PropTypes.string, z: PropTypes.number, transform: PropTypes.string }
  return ZAdjustedElement
}
export const ZCircle = ZAdjusted((p) => <circle {...p} />)
export const ZRect = ZAdjusted((p) => <rect {...p} />)
export const ZPolygon = ZAdjusted((p) => <polygon {...p} />)
export const ZPath = ZAdjusted((p) => <path {...p} />)

const convertableProps = new Set(['d', 'points'])
export function Converted3D(Element) {
  /** @param {React.SVGProps} props */
  function Converted3DElement({ moveTo, lookAt, ...props }) {
    const adjustedProps = { ..._.mapValues(props, (v, k) => (convertableProps.has(k) ? convert3dTo2d(v) : v)) }
    const element = <Element {...adjustedProps} />
    if (moveTo) {
      return <Move to={moveTo}>{element}</Move>
    }
    if (lookAt) {
      return <Look at={lookAt}>{element}</Look>
    }
    return element
  }
  Converted3DElement.propTypes = {
    d: PropTypes.string,
    points: PropTypes.string,
    moveTo: PropTypes.string,
    lookAt: PropTypes.string
  }
  return Converted3DElement
}

export const Line3d = Converted3D((p) => <polyline {...p} />)
export const Polygon3d = Converted3D((p) => <polygon {...p} />)
export const Group3d = Converted3D((p) => <g {...p} />)

/** @param {React.SVGProps} param0 */
export function Rect3d({ points, ...props }) {
  const [[x1, y1, z1], [x2, y2, z2]] = chunk3d(points)

  return (
    <Polygon3d
      points={[
        [x1, y1, z1],
        [x2, y1, y1 === y2 ? z1 : z2],
        [x2, y2, z2],
        [x1, y2, y1 === y2 ? z2 : z1]
      ]}
      {...props}
    />
  )
}
Rect3d.propTypes = {
  points: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number))
  ]).isRequired,
  children: PropTypes.node
}

/** @param {{xray: boolean} & React.SVGProps} param0 */
export function Box3d({ xray = false, name, points, ...props }) {
  let [[x1, y1, z1], [x2, y2, z2]] = chunk3d(points)
  if (x1 > x2) {
    ;[x1, x2] = [x2, x1]
  }
  if (y1 > y2) {
    ;[y1, y2] = [y2, y1]
  }
  if (z1 > z2) {
    ;[z1, z2] = [z2, z1]
  }
  return (
    <g name={name} className={props.moveTo ? 'move' : props.lookAt ? 'look' : undefined}>
      {(x1 > 0 || xray) && <Rect3d name={join(name, 'left')} points={[x1, y1, z1, x1, y2, z2]} {...props} />}
      {(x2 < 0 || xray) && <Rect3d name={join(name, 'right')} points={[x2, y1, z1, x2, y2, z2]} {...props} />}
      {(y1 > 0 || xray) && <Rect3d name={join(name, 'top')} points={[x1, y1, z1, x2, y1, z2]} {...props} />}
      {(y2 < 0 || xray) && <Rect3d name={join(name, 'bottom')} points={[x1, y2, z1, x2, y2, z2]} {...props} />}
      <Rect3d name={join(name, 'front')} points={[x1, y1, z1, x2, y2, z1]} {...props} />
      {xray && <Line3d name={join(name, 'corner')} points={[x1, y2, z2, x2, y2, z2]} {...props} />}
    </g>
  )
}
Box3d.propTypes = {
  points: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number))
  ]).isRequired,
  xray: PropTypes.bool,
  name: PropTypes.string,
  moveTo: PropTypes.string,
  lookAt: PropTypes.string,
  children: PropTypes.node
}
Box3d.defaultProps = {
  strokeMiterlimit: 1,
  fill: 'var(--background-color)'
}

export function Table({ x = 0, y = 200, z = 0, width = 100, length = 250, height = 90, ...props }) {
  return (
    <g name="table" className={props.moveTo ? 'move' : props.lookAt ? 'look' : undefined}>
      <Box3d points={[x + width - 10, y - height + 10, z + length - 10, x + width, y, z + length]} {...props} />
      <Box3d points={[x, y - height + 10, z + length - 10, x + 10, y, z + length]} {...props} />
      <Box3d points={[x + width - 10, y - height + 10, z, x + width, y, z + 10]} {...props} />
      <Box3d points={[x, y - height + 10, z, x + 10, y, z + 10]} {...props} />
      <Box3d points={[x, y - height, z, x + width, y - height + 10, z + length]} {...props} />
    </g>
  )
}
Table.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  z: PropTypes.number,
  width: PropTypes.number,
  length: PropTypes.number,
  height: PropTypes.number,
  moveTo: PropTypes.string,
  lookAt: PropTypes.string,
  children: PropTypes.node
}

export function Chair({ x = 0, y = 200, z = 0, width = 50, length = 50, height = 50, back = 60, ...props }) {
  return (
    <g name="chair" className={props.moveTo ? 'move' : props.lookAt ? 'look' : undefined}>
      <Box3d points={[x + width - 10, y - height + 10, z + length - 10, x + width, y, z + length]} {...props} />
      <Box3d points={[x, y - height + 10, z + length - 10, x + 10, y, z + length]} {...props} />
      <Box3d points={[x + width - 10, y - height + 10, z, x + width, y, z + 10]} {...props} />
      <Box3d points={[x, y - height + 10, z, x + 10, y, z + 10]} {...props} />
      <Box3d points={[x, y - height, z, x + width, y - height + 10, z + length]} {...props} />
      <Box3d points={[x, y - height - back, z, x + 10, y - height, z + length]} {...props} />
    </g>
  )
}
Chair.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  z: PropTypes.number,
  width: PropTypes.number,
  length: PropTypes.number,
  height: PropTypes.number,
  back: PropTypes.number,
  moveTo: PropTypes.string,
  lookAt: PropTypes.string,
  children: PropTypes.node
}

export function Grid({ width = 800, depth = 800 }) {
  return (
    <>
      {_.range(0, 801, 100).map((i) => [
        i < width && <Rect3d points={[-width / 2, -200, i, width / 2, 200, i]} />,
        i < depth && <Rect3d points={[i - width / 2, -200, 0, i - width / 2, 200, depth]} />
      ])}
    </>
  )
}
Grid.propTypes = { width: PropTypes.number, depth: PropTypes.number, children: PropTypes.node }

export function Room({ width = 800, depth = 800 }) {
  return <Box3d name="room" fill="none" points={[-width / 2, -200, 0, width / 2, 200, depth]} xray={true} />
}
Room.propTypes = { width: PropTypes.number, depth: PropTypes.number, children: PropTypes.node }

/** @param {{side: 'back' | 'left' | 'right'} & React.SVGProps} param0 */
export function Door({ side = 'back', x = 0, z = 800, ...props }) {
  return (
    <Rect3d
      name="door"
      points={side === 'back' ? [x - 80, 200, z, x + 80, -100, z] : [x, 200, z - 80, x, -100, z + 80]}
      {...props}
    />
  )
}
Door.propTypes = {
  x: PropTypes.number,
  z: PropTypes.number,
  side: PropTypes.oneOf(['back', 'front', 'left', 'right']),
  children: PropTypes.node
}

/** @param {{side: 'back' | 'front' | 'left' | 'right'} & React.SVGProps} param0 */
export function Arrow({ side = 'back', x, z, ...props }) {
  let points = []
  switch (side) {
    case 'back':
      x = x ?? 0
      z = z ?? 0
      points = [x - 50, 200, z + 100, x + 50, 200, z + 100, x, 200, z]
      break
    case 'front':
      x = x ?? 0
      z = z ?? 1600
      points = [x - 50, 200, z - z / 4, x + 50, 200, z - z / 4, x, 200, z]
      break
  }
  return <Polygon3d points={points} {...props} />
}
Arrow.propTypes = {
  x: PropTypes.number,
  z: PropTypes.number,
  side: PropTypes.oneOf(['back', 'front', 'left', 'right']),
  children: PropTypes.node
}

export function Scene({ children, ...props }) {
  // const [size, setSize] = useState(800)
  // useEffect(() => {
  //   const resize = () => {
  //     let { width, height } = window.visualViewport
  //     setSize(Math.max(height - 250 < width / 2 ? (height - 250) * 2 : width, 100))
  //   }
  //   window.addEventListener('resize', resize)
  //   resize()

  //   return () => window.removeEventListener('resize', resize)
  // }, [])
  return (
    <svg id="scene" viewBox="-400.5 -200.5 801 401" width="100%" height="100%" {...props}>
      {children}
    </svg>
  )
}
Scene.propTypes = { children: PropTypes.node }

export function Starburst() {
  return _.range(-400, 401, 100).map((i) =>
    _.range(200, 401, 100).map((j) => <line key={`${i}-${j}`} x1={0} y1={25} x2={i} y2={j} strokeOpacity={0.5} />)
  )
}

const FLAME_TIME = 750
export function Flame({ x, y, z = 0, delay = '0s', left = false, dur = FLAME_TIME }) {
  const path = left ? `m${x},${y} c-10,-10 -20,-10 0,-60` : `m${x},${y} c10,-10 20,-10 0,-60`
  return (
    <>
      <ZPolygon stroke="red" points="0,0 20,10 0,20" transform="scale(0,0)" z={z}>
        <animateTransform
          attributeName="transform"
          type="scale"
          begin={delay}
          dur={`${dur}ms`}
          from="1"
          to="0"
          repeatCount="indefinite"
        />
        <animateMotion begin={delay} dur={`${dur}ms`} repeatCount="indefinite" path={path} rotate="auto" />
      </ZPolygon>
    </>
  )
}
Flame.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  z: PropTypes.number,
  left: PropTypes.bool,
  dur: PropTypes.number,
  delay: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node
}

export function Torch({ x = 0, y = 0, z = 0 }) {
  const scale = 1 / Math.log2(+z / 400 + 2)
  return (
    <>
      <g transform={`translate(${point3d([x, y, z])})`}>
        <ZCircle stroke="red" r={20} z={z}>
          <animate
            attributeName="r"
            values={[20, 25, 20].map((i) => i * scale).join(';')}
            dur="1s"
            repeatCount="indefinite"
          />
        </ZCircle>
        <Flame x={0} y={-10} delay="0ms" left={true} z={z} />
        <Flame x={-20} y={0} delay={`${FLAME_TIME / 4}ms`} left={true} z={z} />
        <Flame x={20} y={0} delay={`${FLAME_TIME / 2}ms`} left={true} z={z} />
        <Flame x={-10} y={0} delay={`${(FLAME_TIME * 3) / 4}ms`} left={false} z={z} />
        <ZPolygon stroke="brown" points="-12,12 12,12 0,75" z={z} />
      </g>
    </>
  )
}
Torch.propTypes = { x: PropTypes.number, y: PropTypes.number, z: PropTypes.number, children: PropTypes.node }

export function Egg({ x = 0, y = 0, width = 50, height = 100, ...props }) {
  return (
    <ZPath
      d={`m ${x - width / 2},${y - height / 4} q ${width / 2},-${height / 2} ${width},0 s ${width / 2},${height / 2} -${
        width / 2
      },${height / 2} s -${width / 2},-${height / 2} -${width / 2},-${height / 2}`}
      {...props}
    />
  )
}
Egg.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number
}
