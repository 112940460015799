import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useReducer, useState } from 'react'
import { Scene } from '../scene'

// const faces = '🂠🂡🂢🂣🂤🂥🂦🂧🂨🂩🂪🂫🂬🂭🂮🂱🂲🂳🂴🂵🂶🂷🂸🂹🂺🂻🂼🂽🂾🂿🃁🃂🃃🃄🃅🃆🃇🃈🃉🃊🃋🃌🃍🃎🃏🃑🃒🃓🃔🃕🃖🃗🃘🃙🃚🃛🃜🃝🃞🃟🃠🃡🃢🃣🃤🃥🃦🃧🃨🃩🃪🃫🃬🃭🃮🃯🃰🃱🃲🃳🃴🃵'
// const suits = '♠♦♣♥'
// const numbers = 'A23456789TJQK'
// const DECK = _.flatMap(suits, (s, i) => _.map(i > 1 ? _.reverse(Array.from(numbers)) : numbers, (n) => n + s))
// const suits = '♠♥♦♣'
// const numbers = 'A23456789TJQK'
const DECK_52 = Array.from('🂡🂢🂣🂤🂥🂦🂧🂨🂩🂪🂫🂭🂮🂱🂲🂳🂴🂵🂶🂷🂸🂹🂺🂻🂽🂾🃁🃂🃃🃄🃅🃆🃇🃈🃉🃊🃋🃍🃎🃑🃒🃓🃔🃕🃖🃗🃘🃙🃚🃛🃝🃞')
const DECK_TAROT = Array.from('🃠🃡🃢🃣🃤🃥🃦🃧🃨🃩🃪🃫🃬🃭🃮🃯🃰🃱🃲🃳🃴🃵')
const TAROT = [
  'O The Fool',
  'I The Magician / individual',
  'II The High Priestess / childhood',
  'III The Empress / youth',
  'IV The Emperor / maturity',
  'V The Hierophant / old age',
  'VI The Lovers / morning',
  'VII The Chariot / afternoon',
  'VIII Justice / evening',
  'IX The Hermit / night',
  'X Wheel of Fortune / earth and air',
  'XI Strength / water and fire',
  'XII The Hanged Man / dance',
  'XIII Death / shopping',
  'XIV Temperance / open air',
  'XV The Devil / visual arts',
  'XVI The Tower / spring',
  'XVII The Star / summer',
  'XVIII The Moon / autumn',
  'XIX The Sun / winter',
  'XX Judgement / the game',
  'XXI The World / collective'
]

function Card({ card, hidden, x, y, onClick }) {
  return (
    <g>
      <rect
        x={x}
        y={y}
        width={60}
        height={90}
        fill="var(--background-color)"
        stroke="none"
        onClick={onClick}
        cursor={onClick ? 'pointer' : undefined}
      ></rect>
      <text
        x={x}
        y={y + 77}
        fontSize={90}
        fill={!hidden && '🂱' <= card && card <= '🃎' ? 'red' : 'var(--color)'}
        stroke="none"
        pointerEvents="none"
      >
        {hidden ? '🂠' : card}
      </text>
    </g>
  )
}
Card.propTypes = {
  card: PropTypes.string.isRequired,
  hidden: PropTypes.bool,
  x: PropTypes.number,
  y: PropTypes.number,
  onClick: PropTypes.func
}

/**
 *
 * @param {{deck: string[], hand: string[]}} state
 * @param {string} action
 * @returns
 */
function reducer(state, action) {
  let { deck, hand, game } = state
  switch (action) {
    case 'blackjack':
      return blackjack({ ...state, game: 'blackjack' }, 'newgame')
    case 'memory':
      return memory({ ...state, game: 'memory' }, 'newgame')
    case 'tarot':
      deck = _.shuffle(DECK_TAROT)
      hand = deck.splice(0, 1)
      game = 'tarot'
      break
  }

  switch (game) {
    case 'blackjack':
      return blackjack(state, action)
    case 'memory':
      return memory(state, action)
  }

  switch (action) {
    case 'shuffle':
      deck = _.shuffle(deck)
      break
    case 'draw':
      if (deck.length) {
        hand = [...hand, _.last(deck)]
        deck = _.initial(deck)
      }
      break
  }
  return { deck, hand, game }
}

function Blackjack({ state }) {
  const { hand, dealer, done } = state
  return (
    <>
      {dealer &&
        dealer.map((c, i) => (
          <Card
            key={c}
            card={c}
            hidden={!done && i === 0}
            x={-400 + (i % 13) * 60}
            y={-200 + Math.floor(i / 13) * 90}
          />
        ))}

      {hand.map((c, i) => (
        <Card key={c} card={c} x={-400 + (i % 13) * 60} y={110 + Math.floor(i / 13) * -90} />
      ))}
    </>
  )
}
Blackjack.propTypes = {
  state: PropTypes.shape({
    deck: PropTypes.string,
    dealer: PropTypes.arrayOf(PropTypes.string),
    hand: PropTypes.arrayOf(PropTypes.string),
    done: PropTypes.bool
  }),
  dispatch: PropTypes.func
}

/**
 *
 * @param {string[]} hand
 */
function blackjackValue(hand) {
  const values = hand.map((card) => (DECK_52.indexOf(card) % 13) + 1).map((i) => (i > 10 ? 10 : i === 1 ? 11 : i))
  let aces = values.filter((i) => i === 11).length
  let value = _.sum(values)
  while (value > 21 && aces > 0) {
    value -= 10
    aces--
  }
  return value
}

function blackjack(state, action) {
  let { deck, hand, dealer, done, ...other } = state
  switch (action) {
    case 'newgame':
      deck = _.shuffle(DECK_52)
      dealer = [deck.pop(), deck.pop()]
      hand = [deck.pop(), deck.pop()]
      done = false
      break
    case 'hit':
      if (deck.length) {
        hand = [...hand, _.last(deck)]
        deck = _.initial(deck)
      }
      done = blackjackValue(hand) > 21
      break
    case 'stand':
      while (blackjackValue(dealer) < 17 && deck.length) {
        dealer = [...dealer, deck.pop()]
      }
      deck = [...deck]
      done = true
      break
  }
  return { deck, hand, dealer, done, ...other }
}

function init() {
  return {
    deck: [],
    hand: DECK_52,
    game: '52'
  }
}

function MemoryBoard({ state, dispatch }) {
  const { deck, flipped, unflip } = state
  useEffect(() => {
    if (unflip) {
      const id = _.delay(dispatch, 1000, 'unflip')
      return () => {
        window.clearTimeout(id)
      }
    }
  }, [unflip])
  const x = useMemo(() => Math.round((-400 / 13) * (deck.length / 4)), [deck])
  const grid = useMemo(() => _.chunk(deck, deck.length / 4), [deck])
  return (
    <>
      {grid.map((row, j) =>
        row.map((card, i) => (
          <Card
            hidden={!flipped?.[card]}
            key={card}
            card={card}
            onClick={!flipped?.[card] ? () => dispatch({ flip: card }) : undefined}
            x={x + i * 60}
            y={90 + j * -90}
          />
        ))
      )}
    </>
  )
}

MemoryBoard.propTypes = {
  state: PropTypes.shape({
    deck: PropTypes.arrayOf(PropTypes.string),
    flipped: PropTypes.objectOf(PropTypes.bool),
    unflip: PropTypes.string
  }),
  dispatch: PropTypes.func
}

function MemoryText({ state, dispatch }) {
  const { start, end } = state
  const [time, setTime] = useState(0)
  useEffect(() => {
    if (start && !end) {
      let id = -1
      const tick = () => {
        setTime((_.now() - start) / 1000)
        id = window.requestAnimationFrame(tick)
      }
      id = window.requestAnimationFrame(tick)
      return () => window.cancelAnimationFrame(id)
    } else if (start) {
      setTime((end - start) / 1000)
    } else {
      setTime(0)
    }
  }, [start, end])
  return (
    <>
      <p>
        {!start && 'Match value and colors to win!'}
        {end && 'Finish!'} {start && `Time: ${time} seconds`}
      </p>
      <div>
        <button onClick={() => dispatch('easy')}>easy</button>
        <button onClick={() => dispatch('medium')}>medium</button>
        <button onClick={() => dispatch('hard')}>hard</button>
      </div>
    </>
  )
}
MemoryText.propTypes = MemoryBoard.propTypes

function memoryValue(card) {
  const index = DECK_52.indexOf(card)
  return index < 26 ? index : index < 39 ? index - 13 : index - 39
}
function deckRange(valueStart, valueEnd) {
  return [
    ...DECK_52.slice(0 + valueStart, 0 + valueEnd),
    ...DECK_52.slice(13 + valueStart, 13 + valueEnd),
    ...DECK_52.slice(26 + valueStart, 26 + valueEnd),
    ...DECK_52.slice(39 + valueStart, 39 + valueEnd)
  ]
}

function memory({ deck, flipped, match, unflip, start, end, ...other }, action) {
  switch (action) {
    case 'easy':
      deck = _.shuffle(deckRange(10, 13))
      match = ''
      unflip = ''
      flipped = {}
      start = undefined
      end = undefined
      break
    case 'medium':
      deck = _.shuffle(deckRange(0, 6))
      match = ''
      unflip = ''
      flipped = {}
      start = undefined
      end = undefined
      break
    case 'hard':
    case 'newgame':
      deck = _.shuffle(DECK_52)
      match = ''
      unflip = ''
      flipped = {}
      start = undefined
      end = undefined
      break
  }

  if (unflip) {
    flipped = { ...flipped, [match]: false, [unflip]: false }
    match = ''
    unflip = ''
  }

  if (_.isObject(action) && 'flip' in action) {
    if (!start) {
      start = _.now()
    }

    flipped = { ...flipped, [action.flip]: true }
    const isPair = memoryValue(action.flip) === memoryValue(match)

    if (match && !isPair) {
      unflip = action.flip
    } else if (isPair) {
      match = ''
      if (_.size(flipped) === deck.length && _.every(flipped)) {
        end = _.now()
      }
    } else {
      match = action.flip
    }
  }
  return { deck, flipped, match, unflip, start, end, ...other }
}

function Tarot({ state }) {
  const { hand } = state
  const x = useMemo(() => Math.round(-25 * Math.min(hand.length, 6)), [hand])
  const grid = useMemo(() => _.chunk(hand, Math.min(hand.length, 6)), [hand])
  return (
    <>{grid.map((row, j) => row.map((card, i) => <Card key={card} card={card} x={x + i * 60} y={90 + j * -90} />))}</>
  )
}
Tarot.propTypes = MemoryBoard.propTypes

/** @type {React.FunctionComponent} */
export default function Cards() {
  const [state, dispatch] = useReducer(reducer, {}, init)
  const { hand, dealer, game, done } = state
  return (
    <>
      <Scene>
        {game === 'memory' && <MemoryBoard state={state} dispatch={dispatch} />}
        {game === 'blackjack' && <Blackjack state={state} dispatch={dispatch} />}
        {game === 'tarot' && <Tarot state={state} dispatch={dispatch} />}
      </Scene>
      <aside>
        <div>
          <a onClick={() => dispatch('blackjack')}>blackjack</a>
        </div>
        <div>
          <a onClick={() => dispatch('tarot')}>tarot</a>
        </div>
        <div>
          <a onClick={() => dispatch('memory')}>memory</a>
        </div>
      </aside>
      <main>
        {game === 'memory' && <MemoryText state={state} dispatch={dispatch} />}
        {game === 'blackjack' && (
          <>
            {done && <div>dealer: {blackjackValue(dealer)}</div>}
            <div>value: {blackjackValue(hand)}</div>
            {done ? (
              <button onClick={() => dispatch('blackjack')}>new game</button>
            ) : (
              <>
                <button onClick={() => dispatch('hit')}>hit</button>
                <button onClick={() => dispatch('stand')}>stand</button>
              </>
            )}
          </>
        )}
        {game === 'tarot' && (
          <>
            <div>
              {hand.map((c) => (
                <div key={c}>{TAROT[c.codePointAt() - '🃠'.codePointAt()]}</div>
              ))}
            </div>
            <button onClick={() => dispatch('draw')}>draw</button>
          </>
        )}
      </main>
    </>
  )
}
Cards.isExtra = true
