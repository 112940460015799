import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { GameContext } from './model'

export default window.speechSynthesis
  ? function Listen() {
      const [auto, setAutoPlay] = useState(false)
      const userContext = useRef(() => {})
      const { currentlyAt, lookingAt } = useContext(GameContext)
      useEffect(() => {
        if (auto && lookingAt) {
          userContext.current()
        }
      }, [auto, lookingAt])

      useEffect(() => {
        if (auto) {
          if (currentlyAt) {
            userContext.current()
          } else {
            window.speechSynthesis.cancel()
          }
        }
      }, [auto, currentlyAt])

      const play = useCallback(() => {
        userContext.current = () => {
          var message = new SpeechSynthesisUtterance(
            document.querySelector('dialog[open]')?.textContent ?? document.querySelector('main')?.textContent ?? ''
          )
          window.speechSynthesis.cancel()
          window.speechSynthesis.speak(message)
        }
        setAutoPlay(true)
        userContext.current()
      }, [])
      const pause = useCallback(() => {
        setAutoPlay(false)
        window.speechSynthesis.cancel()
      }, [])
      return auto ? (
        <a onClick={pause}>
          <svg viewport="0 0 16 16" width="16" height="16">
            <rect x={0} y={0} width={5} height={16} fill="var(--color)" />
            <rect x={11} y={0} width={5} height={16} fill="var(--color)" />
          </svg>
        </a>
      ) : (
        <a onClick={play}>
          <svg viewport="0 0 16 16" width="16" height="16">
            <polygon points="0,0 0,16 16,8" fill="var(--color)" />
          </svg>
        </a>
      )
    }
  : function NoListen() {
      return null
    }
