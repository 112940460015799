import _ from 'lodash'
import React, { useEffect, useReducer } from 'react'
import { Scene } from '../scene'
import { LocationProps } from '../ui'
import PropTypes from 'prop-types'
const ROTATE = {
  right: 0,
  downright: 45,
  down: 90,
  downleft: 135,
  left: 180,
  upleft: 225,
  up: 270,
  upright: 315
}
function Ship({ position: { x, y, facing }, stroke }) {
  return (
    <polygon
      points="-4,-4 -4,4 4,0"
      transform={`translate(${x * 10 - 190} ${y * 10 - 190})${facing ? ` rotate(${ROTATE[facing]})` : ''}`}
      stroke={stroke}
    />
  )
}
Ship.propTypes = {
  position: PropTypes.object,
  stroke: PropTypes.string
}

/**
 *
 * @param {{
 * position: [number, number, string]
 *  relics: [number, number][]
 *  enemies: [number, number, string][]
 * collisions: Set<string>
 * }} state
 * @param {string} action
 * @returns
 */
function reducer({ grid, player, relics, enemies, score, debris, gameOver, hitAt }, action) {
  let { x, y, facing } = player ?? {}

  if (action === 'reset') {
    return init()
  }
  if (gameOver) {
    return { grid, player, relics, enemies, score, debris, gameOver, hitAt }
  }

  if (action === 'fire') {
    const xOffset = facing.endsWith('left') ? -1 : facing.endsWith('right') ? 1 : 0
    const yOffset = facing.startsWith('up') ? -1 : facing.startsWith('down') ? 1 : 0

    hitAt = [x + xOffset, y + yOffset]
    for (
      let hx = x + xOffset, hy = y + yOffset;
      _.inRange(hx, 0, 39) && _.inRange(hy, 0, 39);
      hx += xOffset, hy += yOffset, hitAt = [hx, hy]
    ) {
      if (grid[hx][hy]) {
        const boom = (bx, by) => {
          if (grid[bx][by]?.type === 'relic') {
            debris.push(spawn(grid, 'debris', bx, by))
          }
          const points = [
            ..._.range(3).flatMap((i) => _.range(3).map((j) => [bx - 2 + i + j, by - i + j])),
            ..._.range(2).flatMap((i) => _.range(2).map((j) => [bx - 1 + i + j, by - i + j]))
          ].filter(([px, py]) => _.inRange(px, 0, 39) && _.inRange(py, 0, 39))

          const enemiesHit = points.filter(([px, py]) => grid[px][py]?.type === 'enemy').map(([px, py]) => grid[px][py])
          score += enemiesHit.length
          enemies = _.without(enemies, enemiesHit)

          if (points.some(([px, py]) => px === x && py === y)) {
            gameOver = true
          }

          points
            .filter(([px, py]) => grid[px][py]?.type !== 'relic' && grid[px][py]?.type !== 'debris')
            .forEach(([px, py]) => debris.push(spawn(grid, 'debris', px, py)))

          points.filter(([px, py]) => grid[px][py]?.type === 'relic').forEach(([px, py]) => boom(px, py))
        }

        switch (grid[hx][hy].type) {
          case 'debris':
            // cannot fire through debris
            break
          case 'enemy':
            enemies = _.without(enemies, grid[hx][hy])
            debris.push(spawn(grid, 'debris', hx, hy))
            score++
            break
          case 'relic':
            // relic goes boom
            boom(hx, hy)
            break
        }
        break
      }
    }
  } else {
    switch (action) {
      case 'upleft':
        x -= 1
        y -= 1
        break
      case 'upright':
        x += 1
        y -= 1
        break
      case 'downleft':
        x -= 1
        y += 1
        break
      case 'downright':
        x += 1
        y += 1
        break
      case 'up':
        y -= 1
        break
      case 'down':
        y += 1
        break
      case 'left':
        x -= 1
        break
      case 'right':
        x += 1
    }
    if (action !== 'wait') {
      facing = action !== 'wait' ? action : facing
      x = _.clamp(x, 0, 38)
      y = _.clamp(y, 0, 38)

      delete grid[player.x][player.y]
      switch (grid[x][y]?.type) {
        case 'debris':
          // reject move
          x = player.x
          y = player.y
          break
        case 'enemy':
          enemies = _.without(enemies, grid[x][y])
          debris.push(spawn(grid, debris, x, y))
          gameOver = true
          break
        default:
          break
      }

      player.x = x
      player.y = y
      player.facing = facing
      grid[x][y] = player
    }
  }

  if (!gameOver) {
    enemies.forEach((enemy) => {
      // first pass: remove from previous positions
      let { x: ex, y: ey } = enemy
      delete grid[ex][ey]

      let dir = ''
      dir += y < ey ? 'up' : y > ey ? 'down' : ''
      dir += x < ex ? 'left' : x > ex ? 'right' : ''

      // move
      ex = x < ex ? ex - 1 : x > ex ? ex + 1 : ex
      ey = y < ey ? ey - 1 : y > ey ? ey + 1 : ey

      enemy.x = ex
      enemy.y = ey
      enemy.facing = dir
    })
    enemies.forEach((enemy) => {
      const { x, y } = enemy
      console.debug('move', enemy, grid[x][y]?.type)
      // second pass: add to new positions
      switch (grid[x][y]?.type) {
        case 'debris':
          // ship crashed into existing debris
          break
        case 'player':
          // ship rams player
          debris.push(spawn(grid, 'debris', x, y))
          gameOver = true
          break
        case 'enemy':
          // new debris
          debris.push(spawn(grid, 'debris', x, y))
          break
        case 'relic':
          // ship collects relic
          grid[x][y] = enemy
          break
        default:
          grid[x][y] = enemy
          break
      }
    })
  }

  enemies = enemies.filter((enemy) => grid[enemy.x][enemy.y] === enemy)

  relics = relics.map((relic) => {
    if (grid[relic.x][relic.y] === relic) return relic
    if (relic.x === x && relic.y === y) {
      score += 2 * enemies.length || 1
      if (enemies.length < 100) {
        enemies.push(..._.range(10).map(() => spawn(grid, 'enemy')))
      }
    }
    return spawn(grid, 'relic')
  })

  if (debris.length > 400) {
    debris.slice(0, -400).forEach(({ x, y }) => delete grid[x][y])
    debris = debris.slice(-400)
  }

  return { grid, player, relics, enemies, score, debris, gameOver, hitAt }
}
function spawn(grid, type, x, y) {
  if (x === undefined && y === undefined) {
    let rx, ry
    do {
      rx = _.random(0, 38)
      ry = _.random(0, 38)
    } while (grid[rx]?.[ry])
    x = rx
    y = ry
  }
  return (grid[x][y] = { type, x, y })
}
function init() {
  const grid = _.range(39).map(() => [])
  const player = (grid[20][20] = { type: 'player', x: 20, y: 20, facing: 'right' })
  return {
    grid,
    player,
    relics: _.range(20).map(() => spawn(grid, 'relic')),
    debris: _.range(20).map(() => spawn(grid, 'debris')),
    enemies: _.range(10).map(() => spawn(grid, 'enemy')),
    hitAt: [0, 0],
    score: 0,
    gameOver: false
  }
}
/** @type {React.FunctionComponent} */
export default function Shooter() {
  const [{ player, enemies, relics, score, debris, gameOver, hitAt }, dispatch] = useReducer(reducer, {}, init)
  const { x, y, facing } = player
  const xOffset = facing.endsWith('left') ? -1 : facing.endsWith('right') ? 1 : 0
  const yOffset = facing.startsWith('up') ? -1 : facing.startsWith('down') ? 1 : 0
  useEffect(() => {
    const onKeypress = (event) => {
      switch (event.key) {
        case '7':
        case 'q':
          dispatch('upleft')
          break
        case '9':
        case 'e':
          dispatch('upright')
          break
        case '1':
        case 'z':
          dispatch('downleft')
          break
        case '3':
        case 'c':
          dispatch('downright')
          break
        case '5':
          dispatch('wait')
          break
        case ' ':
          dispatch('fire')
          document.getElementById('fireTrigger').dispatchEvent(new MouseEvent('click'))
          break
        case '8':
        case 'w':
        case 'ArrowUp':
          dispatch('up')
          break
        case '2':
        case 's':
        case 'ArrowDown':
          dispatch('down')
          break
        case '4':
        case 'a':
        case 'ArrowLeft':
          dispatch('left')
          break
        case '6':
        case 'd':
        case 'ArrowRight':
          dispatch('right')
          break
        case 'r':
          dispatch('reset')
          break
      }
      if (event.key !== ' ') {
        document.getElementById('resetTrigger').dispatchEvent(new MouseEvent('click'))
      }
    }
    window.addEventListener('keydown', onKeypress)
    return () => window.removeEventListener('keydown', onKeypress)
  }, [])
  return (
    <>
      <Scene>
        <rect x={-200} y={-200} width={400} height={400} />
        {!gameOver && <Ship position={player} />}
        <circle id="fireTrigger" />
        <circle id="resetTrigger" />
        {debris.map(({ x, y }) => (
          <path
            key={`collision${x}-${y}`}
            d={`m ${x * 10 - 190} ${y * 10 - 190} m -4,-4 l 8,8 m 0,-8 l -8,8`}
            stroke="grey"
          />
        ))}
        <line
          x1={(x + xOffset * 0.5) * 10 - 190}
          y1={(y + yOffset * 0.5) * 10 - 190}
          x2={hitAt[0] * 10 - 190}
          y2={hitAt[1] * 10 - 190}
          strokeDasharray="0 40 0 0"
        >
          <set
            begin={gameOver ? '' : 'resetTrigger.click'}
            attributeName="stroke-dasharray"
            to="0 40 0 0"
            dur="0ms"
            repeatCount="1"
          />
          <animate
            begin={gameOver ? '' : 'fireTrigger.click'}
            attributeName="stroke-dasharray"
            values="40 0 0 0 ; 40 30 20 10; 0 40 0 0 "
            dur="250ms"
            keyTimes="0; .2; 1"
            repeatCount="1"
          />
        </line>
        {relics.map(({ x, y }) => (
          <rect key={`relic${x}-${y}`} x={x * 10 - 194} y={y * 10 - 194} width={8} height={8} stroke="blue" />
        ))}
        {enemies.map((position, i) => (
          <Ship key={`enemy${i}`} position={position} stroke="red" />
        ))}
      </Scene>
      <main>
        <div>
          {gameOver && <>Game Over!</>} Score: {score}
        </div>
        <div>
          <pre>{`      QWE  Restart: R
Move: ASD
      Z C  Fire: Space`}</pre>
        </div>
      </main>
    </>
  )
}
Shooter.propTypes = LocationProps
Shooter.isExtra = true
