import React from 'react'
import { Move } from '../actions'
import { Door, Room, Scene, Torch } from '../scene'
import { LocationProps, Main } from '../ui'

/** @type {React.FunctionComponent} */
export default function InnRoomE({ firstVisit }) {
  return (
    <>
      <Scene>
        <Room />
        <Door moveTo="InnHall" />
        <Torch x={-200} y={0} z={800} />
      </Scene>
      <Main>
        {firstVisit ? 'You wake up in a room.' : 'Room E.'} There is a <Move to="InnHall">door</Move> leading out.
      </Main>
    </>
  )
}
InnRoomE.propTypes = LocationProps
