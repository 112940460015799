import React from 'react'
import { Details, Item, Look, Move, Take } from '../actions'
import { Chair, Door, Room, Scene, Table, Torch } from '../scene'
import { LocationProps, Main } from '../ui'

/** @type {React.FunctionComponent} */
export default function InnRoomC({ items }) {
  return (
    <>
      <Scene>
        <Room />
        <Door moveTo="InnHall" />
        <Torch x={-200} y={0} z={800} />
        <Table x={300} z={100} lookAt="desk" />
        <Chair x={255} z={150} lookAt="body" />
      </Scene>
      <Main>
        Room C. You see a <Look at="body">old man</Look> sitting at a <Look>desk</Look>. There is a{' '}
        <Move to="InnHall">door</Move> leading out.
      </Main>
      <Details of="body">
        The corpse of an old man is slumped on the desk. A dark ooze seeps out from his mouth.{' '}
        <Item item={items.staff}>
          There is a <Take>staff</Take> next to him.
        </Item>
      </Details>
      <Details of="desk">
        <Item item={items.potion} or="You have already looted everything of value.">
          You rifle through some drawers and find some <Take>potions</Take>.
        </Item>
      </Details>
    </>
  )
}
InnRoomC.items = {
  staff: { id: 'staff' },
  potion: {
    id: 'potion',
    quantity: 2
  }
}
InnRoomC.propTypes = LocationProps
